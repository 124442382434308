<template>
  <div class="viewContainer" >
    <v-container
      :class="['pa-4', 'pa-sm-8', $store.state.app.classExpandContainer]"
    >
      <!-- <v-card class="content-tabs-wrapper pa-4 pa-sm-8 mb-4" elevation="0">
        <x-select
          v-model="provider_uuid"
          :items="item_provider"
          @change="getDataPrice"
          item-text="name"
          item-value="uuid"
          :label="$t('provider')"
          filled
        ></x-select>   

        <v-tabs
          v-model="tab_date"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab @click="getDataPrice(1,'week'); tab_date='last_1_week';" :href="'#last_1_week'" class="no-caps" :ripple="false">
            {{ $t('last_1_week') }}
          </v-tab>
          <v-tab @click="getDataPrice(1,'month'); tab_date='last_1_month';" :href="'#last_1_month'" class="no-caps" :ripple="false">
            {{ $t('last_1_month') }}
          </v-tab>
          <v-tab @click="getDataPrice(3,'month'); tab_date='last_3_month';" :href="'#last_3_month'" class="no-caps" :ripple="false">
            {{ $t('last_3_month') }}
          </v-tab>
          <v-tab @click="getDataPrice(6,'month'); tab_date='last_6_month';" :href="'#last_6_month'" class="no-caps" :ripple="false">
            {{ $t('last_6_month') }}
          </v-tab>
          <v-tab @click="getDataPrice(1,'year'); tab_date='last_1_year';" :href="'#last_1_year'" class="no-caps" :ripple="false">
            {{ $t('last_1_year') }}
          </v-tab>
        </v-tabs>
      </v-card>
        <v-card 
          v-if="loading_chart" 
          align="center"
          justify="center"
          class="fill-height"
        >
          <v-progress-circular
            :size="32"
            width="3"
            color="ctaBg"
            indeterminate
            class="ma-12"
          ></v-progress-circular>
        </v-card>

      <v-card v-if="!loading_chart" class="content-tabs-wrapper pa-4 pa-sm-8  mb-4" elevation="0">
        <highcharts 
          class="hc" 
          :options="option_cost_price_line" 
          ref="cost_price">
        </highcharts>
      </v-card>
      <v-card v-if="!loading_chart" class="content-tabs-wrapper pa-4 pa-sm-8  mb-4" elevation="0">
        <highcharts 
          class="hc" 
          :options="option_cost_price_pie" 
          ref="cost_price">
        </highcharts>
      </v-card> -->
      

      <v-card class="content-tabs-wrapper pa-4 pa-sm-8 mb-4" elevation="0">
        <data-table
          model="App\Models\Orders"
        >
          <template v-slot:empty-head>
            <v-icon size="72">mdi-folder-multiple</v-icon>
          </template>
          <template v-slot:empty-text>
          </template>
        </data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tab_date: 'last_1_week',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: true,
    business: null,
    search: '',
    item_provider: [],
    provider_uuid: null,
    loading_chart: true,
    option_cost_price_line: {
      title: {
          text: '',
      },
      credits: {
        enabled: false
      },
      yAxis: {
          title: {
              text: ''
          }
      },
      xAxis: {
        categories: []
      },
      legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
      },
      series: [],

      responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  legend: {
                      layout: 'horizontal',
                      align: 'center',
                      verticalAlign: 'bottom'
                  }
              }
          }]
      },
    },
    option_cost_price_pie: {
      chart: {
          type: 'pie'
      },
      title: {
          text: 'cost_price'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
          series: {
              dataLabels: {
                  enabled: true,
                  formatter: function() {
                      return Math.round(this.percentage*100)/100 + ' %';
                  },
                  distance: -30,
                  color:'white'
              }
          }
      },
      series: [
          {
              name: 'Percentage',
              colorByPoint: true,
              data: []
          }
      ]
    }
  }),
  created () {
    // Set locale
    this.locale = (this.$auth.check()) ? this.$auth.user().locale: Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
    this.moment.locale(this.locale.substr(0,2))

    // this.axios.get('/admin/provider/get-not-serpclick')
    // .then(res => {
    //   if (res.data.status === 'success') {
    //     //console.log(res.data)
    //     this.item_provider = res.data.providers
    //     this.provider_uuid = res.data.providers[0].uuid
    //     this.getDataPrice(1,'week')
    //   }
    // })
    // .catch(err => {
    //   if (err.response.data.status === 'error') {
    //     this.$root.$snackbar(err.response.data.msg)
    //   }
    // })
  },
  methods: {
    getDataPrice(day,format){
      this.loading_chart = true
      this.axios.post('/admin/backlink/get-price-chart',{
        uuid: this.provider_uuid,
        day: day,
        format: format
      })
      .then(res => {
        if (res.data.status === 'success') {
          //console.log(res.data)
          let categories = []
          let data_pirce = []
          let data_cost = []
          let data_profit = []
          for(let key in res.data.line){
            categories.push(key)
            data_pirce.push(parseFloat(res.data.line[key].price))
            data_cost.push(parseFloat(res.data.line[key].cost))
            data_profit.push(parseFloat(res.data.line[key].profit))
          }
          this.option_cost_price_line.title.text = this.$t('cost_profit')
          this.option_cost_price_line.yAxis.text = this.$t('amount')
          this.option_cost_price_line.xAxis.categories = categories
          this.option_cost_price_line.series = [
            {
              name: this.$t('pirce'),
              data: data_pirce
            },
            {
              name: this.$t('cost'),
              data: data_cost
            },
            {
              name: this.$t('profit'),
              data: data_profit
            },
          ]
          

          this.option_cost_price_pie.title.text = this.$t('cost_profit')
          this.option_cost_price_pie.series = [
              {
                  name: this.$t('amount'),
                  colorByPoint: true,
                  data: [
                      {
                          name: this.$t('pirce'),
                          y: parseFloat(res.data.pie.price)
                      },
                      {
                          name: this.$t('cost'),
                          y: parseFloat(res.data.pie.cost)
                      },
                      {
                          name: this.$t('profit'),
                          y: parseFloat(res.data.pie.profit)
                      },
                  ]
              }
          ]
        }
      })
      .catch(err => {
        if (err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => this.loading_chart = false)
    },
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).tz(this.$auth.user().timezone).format(format)
      }
      return dt
    },
  }
}
</script>