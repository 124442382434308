<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper pa-4 pa-sm-8" elevation="0">
        <menu_keyword/>

        <v-card rounded outlined class="mt-5 pa-5" >
          <h2><b>{{ $t('dashboard_api') }}</b></h2>
          {{ $t('title_dashboard_api') }}
          <v-row no-gutters class="mt-2">
            <v-col cols="12" sm="12" md="10" lg="10" class="ma-auto">
              <div v-if="loading_token" class=" ma-auto">
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </div>
              <div v-if="!loading_token" class="d-flex ma-auto">
                <!-- <a :href="$init.config.schemeAndHost + '/v1/dashboard?token=' + token_dashboard" target="_blank">
                  {{ $init.config.schemeAndHost + '/v1/dashboard?token=' + token_dashboard }}
                </a> -->
                <x-text-field
                  v-model="token_dashboard"
                  class="input-token"
                  readonly
                >
                </x-text-field>
              </div>
            </v-col>
            <v-col class="d-flex justify-center ma-auto mt-2" cols="12" sm="12" md="2" lg="2">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn small fab dark v-on="on"
                    @click="copyStringToClipboard(token_dashboard)"
                    class="border-radius-circle mr-2" 
                    color="primary">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                </template>
                {{ $t('copy') }}
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn small fab dark v-on="on"
                    @click="refreshToken"
                    class="border-radius-circle mr-2" 
                    color="primary"
                    :loading="loading_refresh_token"
                    >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
                </template>
                {{ $t('refresh_token') }}
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>

          <v-card rounded outlined class="mt-5 pa-5" >
            <v-card-title class="pa-0">
              <v-card-text class="pl-0 pr-0 d-flex justify-space-between">
                <h2 class="">{{ $t('chart_ranking') }}</h2>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small fab dark v-on="on"
                      @click="filterDashboard('chart_ranking')"
                      class="border-radius-circle" 
                      color="primary"
                      >
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                  </template>
                  {{ $t('filters') }}
                </v-tooltip>
              </v-card-text>
            </v-card-title>
            <v-card v-if="loading_report_ranking" flat rounded class="content-tabs-card ma-5 pt-5 pb-5">
              <v-skeleton-loader
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </v-card>
            <div v-if="!loading_report_ranking && !has_chart_ranking_active" class="pa-0 d-flex justify-center" style="height: 250px;">
              <h2 class="ma-auto">{{ $t('no_data') }}</h2>
            </div>
            <div v-if="!loading_report_ranking && has_chart_ranking_active" class="pa-0">
              <v-tabs 
                v-if="has_tab"
                v-model="tab_chart_ranking_active"
                :color="($store.state.app.dark) ? 'white' : 'black'"
                class="content-tabs mb-3"
                background-color="transparent"
                fixed-tabs
                show-arrows
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab v-if="has_all" @click="setChartRankingActive('all')" href="#all" class="no-caps" :ripple="false">
                  {{ $t('ranking') }} {{ $t('all') }}
                </v-tab>
                <v-tab v-if="has_rank_group" @click="setChartRankingActive('rank_group')" href="#rank_group" class="no-caps" :ripple="false">
                  {{ $t('ranking') }} {{ $t('url') }}
                </v-tab>
                <v-tab v-if="has_rank_absolute" @click="setChartRankingActive('rank_absolute')" href="#rank_absolute" class="no-caps" :ripple="false">
                  {{ $t('ranking_ads') }} {{ $t('url') }}
                </v-tab>
                <v-tab v-if="has_rank_group_domain" @click="setChartRankingActive('rank_group_domain')" href="#rank_group_domain" class="no-caps" :ripple="false">
                  {{ $t('ranking') }} {{ $t('best_website') }}
                </v-tab>
                <v-tab v-if="has_rank_absolute_domain" @click="setChartRankingActive('rank_absolute_domain')" href="#rank_absolute_domain" class="no-caps" :ripple="false">
                  {{ $t('ranking_ads') }} {{ $t('best_website') }}
                </v-tab>
              </v-tabs>
              <highcharts :constructorType="'stockChart'" class="hc" :options="chart_ranking_active" ref="chart"></highcharts>
            </div>
          </v-card> 

          <v-card rounded outlined class="mt-5 pa-5">
            <v-card-title class="pa-0">
              <v-card-text class="pl-0 pr-0 d-flex justify-space-between">
                <h2 class="">
                  {{ $t('keyword_performance') }}
                  <v-btn-toggle
                    v-model="toggle_keyword_performance"
                    color="green accent-4"
                    group
                  >
                    <v-btn class="border-radius-circle" value="stack" @click="changeTypeChart('stack')">
                      <v-icon>mdi-chart-bar-stacked</v-icon>
                    </v-btn>
                    <v-btn class="border-radius-circle" value="bar" @click="changeTypeChart('bar')">
                      <v-icon>mdi-chart-bar</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </h2>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small fab dark v-on="on"
                      @click="filterDashboard('keyword_performance')"
                      class="border-radius-circle" 
                      color="primary"
                      >
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                  </template>
                  {{ $t('filters') }}
                </v-tooltip>
              </v-card-text>
            </v-card-title>
            <v-card v-if="loading_keyword_performance" flat rounded class="content-tabs-card ma-5 pt-5 pb-5">
              <v-skeleton-loader
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </v-card>
            <div v-if="!loading_keyword_performance && !has_keyword_performance" class="pa-0 d-flex justify-center" style="height: 250px;">
              <h2 class="ma-auto">{{ $t('no_data') }}</h2>
            </div>
            <div v-if="!loading_keyword_performance && has_keyword_performance" class="pa-0">
              <v-tabs 
                v-if="has_tab"
                v-model="tab_keyword_performance"
                :color="($store.state.app.dark) ? 'white' : 'black'"
                class="content-tabs mb-3"
                background-color="transparent"
                fixed-tabs
                show-arrows
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab v-if="has_all" @click="setKeywordPerformance('all')" href="#all" class="no-caps" :ripple="false">
                  {{ $t('ranking') }} {{ $t('all') }}
                </v-tab>
                <v-tab v-if="has_rank_group" @click="setKeywordPerformance('rank_group')" href="#rank_group" class="no-caps" :ripple="false">
                  {{ $t('ranking') }} {{ $t('url') }}
                </v-tab>
                <v-tab v-if="has_rank_absolute" @click="setKeywordPerformance('rank_absolute')" href="#rank_absolute" class="no-caps" :ripple="false">
                  {{ $t('ranking_ads') }} {{ $t('url') }}
                </v-tab>
                <v-tab v-if="has_rank_group_domain" @click="setKeywordPerformance('rank_group_domain')" href="#rank_group_domain" class="no-caps" :ripple="false">
                  {{ $t('ranking') }} {{ $t('best_website') }}
                </v-tab>
                <v-tab v-if="has_rank_absolute_domain" @click="setKeywordPerformance('rank_absolute_domain')" href="#rank_absolute_domain" class="no-caps" :ripple="false">
                  {{ $t('ranking_ads') }} {{ $t('best_website') }}
                </v-tab>
              </v-tabs>
              

              <highcharts 
              v-show="type_chart_keyword_performance == 'stack'"
              class="hc" 
              :options="option_keyword_performance" 
              ref="option_keyword_performance">
              </highcharts>

              <highcharts 
              v-show="type_chart_keyword_performance == 'bar'"
              class="hc" 
              :options="option_keyword_performance_bar" 
              ref="option_keyword_performance_bar">
              </highcharts>

            </div>
            
          </v-card> 

          <v-card rounded outlined class="mt-5 pa-5" >
            <v-card v-if="loading_keyword_search_volume" flat tile class="content-tabs-card">
              <v-skeleton-loader
                class="mx-auto"
                type="table"
              ></v-skeleton-loader>
            </v-card>
            <v-card-title v-if="!loading_keyword_search_volume" class="pa-0">
              <v-card-text class="pl-0 pr-0 d-flex justify-space-between">
                <h2 class="">{{ $t('table_keyword_rank') }}</h2>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small fab dark v-on="on"
                      @click="filterDashboard('table_keyword_rank')"
                      class="border-radius-circle" 
                      color="primary"
                      >
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                  </template>
                  {{ $t('filters') }}
                </v-tooltip>
              </v-card-text>

              <div v-for="(item , index) in array_domain" :key="index" class="chip_domain">
                  <v-chip 
                  close
                  @click:close="removeDomain(item.uuid,index)"
                  class="mr-1 pt-6 pb-6 mt-1 mb-1"
                  >
                    {{encodeDecodeURL(item.domain)}}
                  </v-chip>
              </div>
              <div class="d-flex">
                
                <x-text-field
                  v-if="has_insert_rival"
                  v-model="input_domain"
                  class="ma-auto input-rival ml-1 mr-2"
                >
                </x-text-field>
                <div class="d-flex ma-auto">
                  <v-btn v-if="!has_insert_rival" small fab dark class="border-radius-circle" color="primary" @click="has_insert_rival = true">
                    <v-icon >mdi-plus</v-icon>
                  </v-btn>
                  <v-btn 
                  v-if="has_insert_rival" 
                  outlined
                  fab
                  small
                  class="border-radius-circle mr-2" 
                  color="ctaBg" 
                  @click="has_insert_rival = false; input_domain = ''"
                  >
                    <v-icon >mdi-close</v-icon>
                  </v-btn>
                  <v-btn 
                  v-if="has_insert_rival" 
                  small fab 
                  class="border-radius-circle " 
                  color="primary" 
                  :loading="loading_add_domain"
                  :disabled="loading_add_domain"
                  @click="addDomain"
                  >
                    <v-icon >mdi-check</v-icon>
                  </v-btn>
                  
                </div>
     
              </div>
  
            </v-card-title>
            <div v-if="!has_table_keyword_rank" class="pa-4 d-flex justify-center" style="height: 250px;">
              <h2 class="ma-auto">{{ $t('no_data') }}</h2>
            </div>
            <div class="" v-if="!loading_keyword_search_volume && has_table_keyword_rank">
              <div class="d-flex justify-end mt-2 mb-1">
                <x-text-field
                  class="input-search"
                  v-model="search_rank"
                  append-icon="mdi-magnify"
                  label="Search"
                  :loading="table_loading_rank"
                  single-line
                  hide-details
                  clearable
                ></x-text-field>
              </div>
              <v-data-table
                rounded
                :mobile-breakpoint="0"
                class="elevation-1"
                :headers="headers_rank"
                :items="desserts_rank"
                :items-per-page="10"  
                :search="search_rank"
                @update:sort-by="updateSortBy"
                @update:sort-desc="updateSortDesc"
                :header-props="{
                    'sort-icon': (sortedDesc ? 'mdi-arrow-up' : 'mdi-arrow-down')
                }"
                :footer-props="{
                  itemsPerPageText: $t('rows_per_page'),
                  itemsPerPageOptions: [10, 25, 50, 100, 250, { text: $t('all'), value: -1 }],  
                  itemsPerPageAllText: $t('all'),
                  pageText: $t('page_of'),
                  showFirstLastPage: true,
                  firstIcon: 'mdi-chevron-double-left',
                  lastIcon: 'mdi-chevron-double-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right'
                }"
              >

              <!-- <template v-slot:item="props">
                <tr>
                  <td :class="getItemClass(props.item)"></td>
                </tr>
              </template> -->
              <template 
              :slot="'header.'+ item.value" 
              slot-scope="props" 
              v-for="(item, index) in headers_rank"
              >
                <div 
                  v-if="item" 
                  :key="index"
                  :class="[(!sortedBy || sortedBy != item.value ? 'hide-icon' : '')]"
                >   
                  <span>{{ item.text }}</span>
                </div>
              </template>

              <template :slot="'item.' + item.value" slot-scope="props" v-for="(item, index) in headers_rank">
                <div 
                  v-if="item" 
                  :key="'col_' + index"
                >
                  <div v-if="props.item[item.value] && item.value == 'monthly_search'">
                    <!-- <v-sparkline
                      :value="props.item[item.value]"
                      :gradient="gradient"
                      :smooth="radius || false"
                      :padding="padding"
                      :line-width="width"
                      :stroke-linecap="lineCap"
                      :gradient-direction="gradientDirection"
                      :fill="fill"
                      :type="type"
                      :auto-line-width="autoLineWidth"
                      auto-draw
                    ></v-sparkline> -->

                    <sparkline :seriesData="props.item[item.value]" />
                    
                  </div>
                  <div v-else v-html="props.item[item.value]">
                    
                  </div>
                </div>
              </template>
              
              </v-data-table>
            </div>
          </v-card>

          <v-card rounded outlined class="mt-5 pa-5">
            <v-card-title class="pa-0">
              <v-card-text class="pl-0 pr-0 d-flex justify-space-between">
                <h2 class="">{{ $t('table_keyword_performance_historical') }}</h2>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small fab dark v-on="on"
                      @click="filterDashboard('table_keyword_performance_historical')"
                      class="border-radius-circle" 
                      color="primary"
                      >
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                  </template>
                  {{ $t('filters') }}
                </v-tooltip>
              </v-card-text>
            </v-card-title>
            <v-card v-if="loading_keyword_performance_historical" flat rounded class="content-tabs-card ma-5 pt-5 pb-5">
              <v-skeleton-loader
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </v-card>
            <div v-if="!loading_keyword_performance_historical && !has_table_keyword_performance_historical" class="pa-0 d-flex justify-center" style="height: 250px;">
              <h2 class="ma-auto">{{ $t('no_data') }}</h2>
            </div>
            <div v-if="!loading_keyword_performance_historical && has_table_keyword_performance_historical" class="pa-0">
              <v-tabs 
                v-if="has_tab"
                v-model="tab_table_keyword_performance_historical"
                :color="($store.state.app.dark) ? 'white' : 'black'"
                class="content-tabs mb-3"
                background-color="transparent"
                fixed-tabs
                show-arrows
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab v-if="has_all" @click="setTableKeywordPerformanceHistorical('all')" href="#all" class="no-caps" :ripple="false">
                  {{ $t('ranking') }} {{ $t('all') }}
                </v-tab>
                <v-tab v-if="has_rank_group" @click="setTableKeywordPerformanceHistorical('rank_group')" href="#rank_group" class="no-caps" :ripple="false">
                  {{ $t('ranking') }} {{ $t('url') }}
                </v-tab>
                <v-tab v-if="has_rank_absolute" @click="setTableKeywordPerformanceHistorical('rank_absolute')" href="#rank_absolute" class="no-caps" :ripple="false">
                  {{ $t('ranking_ads') }} {{ $t('url') }}
                </v-tab>
                <v-tab v-if="has_rank_group_domain" @click="setTableKeywordPerformanceHistorical('rank_group_domain')" href="#rank_group_domain" class="no-caps" :ripple="false">
                  {{ $t('ranking') }} {{ $t('best_website') }}
                </v-tab>
                <v-tab v-if="has_rank_absolute_domain" @click="setTableKeywordPerformanceHistorical('rank_absolute_domain')" href="#rank_absolute_domain" class="no-caps" :ripple="false">
                  {{ $t('ranking_ads') }} {{ $t('best_website') }}
                </v-tab>
              </v-tabs>

              <div class="d-flex justify-end mt-2 mb-1">
                <x-text-field
                  class="input-search"
                  v-model="search_keyword_performance_historical"
                  append-icon="mdi-magnify"
                  label="Search"
                  :loading="table_loading_keyword_performance_historical"
                  single-line
                  hide-details
                  clearable
                ></x-text-field>
              </div>

              <v-data-table
                rounded
                :mobile-breakpoint="0"
                class="elevation-1"
                :headers="headers_keyword_performance_historical"
                :items="desserts_keyword_performance_historical"
                :items-per-page="10"  
                :search="search_keyword_performance_historical"
                :footer-props="{
                  itemsPerPageText: $t('rows_per_page'),
                  itemsPerPageOptions: [10, 25, 50, 100, 250, { text: $t('all'), value: -1 }],  
                  itemsPerPageAllText: $t('all'),
                  pageText: $t('page_of'),
                  showFirstLastPage: true,
                  firstIcon: 'mdi-chevron-double-left',
                  lastIcon: 'mdi-chevron-double-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right'
                }"
              >
              </v-data-table>
            </div>
          </v-card>

          <v-card rounded outlined class="mt-5 pa-5">  
            <v-card-title class="pa-0">
              <v-card-text class="pl-0 pr-0 d-flex justify-space-between">
                <h2 class="">{{ $t('backlink') }}</h2>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small fab dark v-on="on"
                      @click="filterDashboard('backlink')"
                      class="border-radius-circle" 
                      color="primary"
                      >
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                  </template>
                  {{ $t('filters') }}
                </v-tooltip>
              </v-card-text>
            </v-card-title>
            <v-card v-if="loading_backlink" flat rounded class="content-tabs-card ma-5 pt-5 pb-5">
              <v-skeleton-loader
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </v-card>
            <div v-if="!loading_backlink && !has_backlink" class="pa-0 d-flex justify-center" style="height: 250px;">
              <h2 class="ma-auto">{{ $t('no_data') }}</h2>
            </div>
            <div class="pa-0" v-if="!loading_backlink && has_backlink">
              <highcharts :constructorType="'stockChart'" class="hc" :options="chart_backlink" ref="chart"></highcharts>
            </div>
          </v-card>  
          
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { copyStringToClipboard } from '@/utils/helpers';

const gradients = [
    ['#222'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    ['purple', 'violet'],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
]

export default {
  data: () => ({
    locale: 'en',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    type_filters: [
      'table_keyword_rank',
      'chart_ranking',
      'keyword_performance',
      'table_keyword_performance_historical',
      'backlink',
    ],
    filters:{},
    historical_data:{},
    week_date:{},
    ranking_avg: true,
    count_api: 0,
    loading: false,
    loading_main: true,
    loading_refresh_token: false,
    loading_keyword_search_volume: true,
    keyword_search_volume: [],
    loading_report_ranking: true,
    report_ranking: [],
    loading_keyword_performance: true,
    toggle_keyword_performance: 'stack',
    type_chart_keyword_performance: 'stack',
    keyword_performance: [],
    loading_keyword_performance_historical: true,
    keyword_performance_historical: [],
    loading_backlink: true,
    has_tab: true,
    tab_table_keyword_rank: 'all',
    tab_chart_ranking_active: 'all',
    tab_keyword_performance: 'all',
    tab_table_keyword_performance_historical: 'all',
    has_all: true,
    has_rank_group: true,
    has_rank_absolute: true,
    has_rank_group_domain: true,
    has_rank_absolute_domain: true,
    has_table_keyword_rank: true,
    has_chart_ranking_active: true,
    has_keyword_performance: true,
    has_table_keyword_performance_historical: true,
    has_backlink: true,
    table_loading_rank: false,
    search_rank: '',
    currentSortColumn: null,
    hoveredColumn: null,
    isSortDesc: false,
    sortedBy: null,
    sortedDesc: false,
    headers_rank: [],
    desserts_rank: [],
    table_loading_keyword_performance_historical: false,
    search_keyword_performance_historical: '',
    headers_keyword_performance_historical: [],
    desserts_keyword_performance_historical: [],
    option_keyword_performance: {
        chart: {
            type: 'column',
            style: {
              fontFamily: 'Helvetica, Arial, sans-serif'
            },
        },
        colors: ['#1abc9c', '#78d596', '#bceb94', '#ffff9d', '#fcc267', '#d43d51'],
        credits: {
          enabled: false
        },
        title: false,
        xAxis: {
            categories: [],
            labels: {
              style: {
                color: '#000000'
              }
            }
        },
        yAxis: {
            min: 0,
            title: false,
            stackLabels: {
                enabled: true
            },
            labels: {
              style: {
                color: '#000000'
              }
            }
        },
        legend: {
            align: 'center',
            x: 0,
            verticalAlign: 'top',
            y: 0,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        exporting: {
          buttons: {
              contextButton: {
                  symbol: 'menu',
                  align: 'right', 
                  verticalAlign: 'top', 
                  y: -3 
              },
          }
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>{point.log}<br/>'
            // pointFormat: '{series.name}: {point.y}<br/>'+this.$t('total')+': {point.stackTotal}<br>{point.log}<br/>'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: []
    },
    option_keyword_performance_bar: {
        chart: {
            type: 'column',
            style: {
              fontFamily: 'Helvetica, Arial, sans-serif'
            },
        },
        colors: ['#1abc9c', '#78d596', '#bceb94', '#ffff9d', '#fcc267', '#d43d51'],
        credits: {
          enabled: false
        },
        title: false,
        xAxis: {
            categories: [],
            labels: {
              style: {
                color: '#000000'
              }
            }
        },
        yAxis: {
            min: 0,
            title: false,
            stackLabels: {
                enabled: true
            },
            labels: {
              style: {
                color: '#000000'
              }
            }
        },
        legend: {
            align: 'center',
            x: 0,
            verticalAlign: 'top',
            y: 0,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {  
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>{point.log}<br/>'
        },
        plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
        },
        series: []
    },
    option_keyword_performance_historical: {
      title: {
					text: ''
      }
    },
    option_backlink: {
      title: {
					text: ''
      }
    },
    spLineStyles1:{
      stroke: "#54a5ff",
    },
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: 'round',
    gradient: gradients[5],
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    gradientDirection: 'top',
    gradients,
    fill: false,
    type: 'trend',
    autoLineWidth: false,
    con: ['top_3','page_1','page_2','page_3','top_100','no_rank'],
    // color:{
    //   top_3: '#FFC400',
    //   page_1: '#FB8C00',
    //   page_2: '#546E7A',
    //   page_3: '#BF360C',
    //   top_100: '#40C4FF',
    //   no_rank: '#546E7A',
    // },
    color:{
      top_3: '#1abc9c',
      page_1: '#78d596',
      page_2: '#bceb94',
      page_3: '#ffff9d',
      top_100: '#fcc267',
      no_rank: '#d43d51',
    },
    array_domain: [],
    has_insert_rival: false,
    input_domain: "",
    status_active: true,
    loading_token: true,
    token_dashboard: null,
    dashboard: null,
    array_keyword: [],
    chart_ranking_active: {
        chart: {
          style: {
            fontFamily: 'Helvetica, Arial, sans-serif'
          },
        },
        series: [
          {
            name: 'A',
            data: [[1619032324000,100], [1619112420000,80], [1619193730000,50]]
          }
        ],
        credits: {
          enabled: false
        },
        // chart: {
        //   spacingTop: 70, // เพิ่มพื้นที่ว่างด้านบน 50px
        // },
        navigator: {
          // top: 1,
          enabled: true,
          outlineColor: '#5D5D5E',
          adaptToUpdatedData: false,
          maskFill: 'rgba(93, 93, 94, 0.5)',
          series: {
            color: '#000000',
            lineColor: '#000000'
          },
          handles: {
            backgroundColor: '#FFFFFF',
            borderColor: '#5D5D5E'
          },
          xAxis: {
            labels: {
              enabled: false // Disabling labels on the navigator
            }
          }
        },
        scrollbar: {
          enabled: false // Disabling the scrollbar entirely
        },
        exporting: {
          buttons: {
              contextButton: {
                  symbol: 'menu',
                  align: 'right', 
                  verticalAlign: 'top', 
                  y: -3 
              },
          }
        },
        rangeSelector: {
          enabled: true,
          inputEnabled: true,
          selected: 4,
          inputDateFormat: '%d/%m/%Y',
          inputEditDateFormat: '%d/%m/%Y',
          inputBoxWidth: 100,
          inputBoxHeight: 18,
          inputStyle: { // Styling the text inside the input boxes
              color: '#000', // Text color
              fontWeight: 'bold', // Text weight
              borderRadius: '4px', // Adds rounded corners
              padding: '5px' // Optional: adds padding inside the input boxes
          },
          buttons: [{
              type: 'week',
              count: 1,
              text: '1w'
          }, {
              type: 'month',
              count: 1,
              text: '1m'
          }, {
              type: 'month',
              count: 2,
              text: '2m'
          }, {
              type: 'month',
              count: 6,
              text: '6m'
          }, {
              type: 'year',
              count: 1,
              text: '1y'
          }, {
              type: 'all',
              text: 'All'
          }],
          buttonTheme: {
            fill: 'none',
            stroke: 'none',
            'stroke-width': 0,
            style: {
              color: '#000000',
              fontWeight: 'bold'
            },
            states: {
              hover: {
                fill: '#5D5D5E',
                style: {
                  color: 'white'
                }
              },
              select: {
                fill: '#000000',
                style: {
                  color: 'white'
                }
              }
            }
          }
        },
        xAxis: {
          lineColor: '#5D5D5E',
          opposite: true,
          type: 'datetime',
          tickPixelInterval: 200,
          // dateTimeLabelFormats: {
          //     day: '%e/%m/%Y' // Day format
          // },
          labels: {
              style: {
                color: '#000000'
              },
              //format: '{value:%e/%m/%Y}', // Display date format
              rotation: 0, // Set rotation to 0 for no tilt
              align: 'center' // Align labels to center under the tick
          },
        },
        yAxis: {
          allowDecimals: false,
          reversed: true,
          min: 1,
          minRange: 1,
          // startOnTick: false,
          // endOnTick: false,
          title: {
            text: 'อันดับ/วันเวลา'
          },
          startOnTick: false,
          endOnTick: false,
          gridLineColor: 'rgba(0, 0, 0, 0.05)',
          gridLineWidth: 1,
          gridLineDashStyle: 'Solid', // This is already the default, but specifying it for clarity
          labels: {
            style: {
              color: '#000000'
            }
          }
        },
        global: {
          useUTC: false
        }
      },
      chart_backlink: {
        chart: {
          style: {
            fontFamily: 'Helvetica, Arial, sans-serif'
          },
        },
        series: [
          {
            name: 'A',
            data: [[1619032324000,100], [1619112420000,80], [1619193730000,50]]
          }
        ],
        credits: {
          enabled: false
        },
        navigator: {
          enabled: true,
          outlineColor: '#5D5D5E',
          adaptToUpdatedData: false,
          maskFill: 'rgba(93, 93, 94, 0.5)',
          series: {
            color: '#000000',
            lineColor: '#000000'
          },
          handles: {
            backgroundColor: '#FFFFFF',
            borderColor: '#5D5D5E'
          },
          xAxis: {
            labels: {
              enabled: false // Disabling labels on the navigator
            }
          }
        },
        scrollbar: {
          enabled: false // Disabling the scrollbar entirely
        },
        exporting: {
          buttons: {
              contextButton: {
                  symbol: 'menu',
                  align: 'right', 
                  verticalAlign: 'top', 
                  y: -3 
              },
          }
        },
        rangeSelector: {
          enabled: true,
          inputEnabled: true,
          selected: 4,
          inputDateFormat: '%d/%m/%Y',
          inputEditDateFormat: '%d/%m/%Y',
          inputBoxWidth: 100,
          inputBoxHeight: 18,
          inputStyle: { // Styling the text inside the input boxes
              color: '#000', // Text color
              fontWeight: 'bold', // Text weight
              borderRadius: '4px', // Adds rounded corners
              padding: '5px' // Optional: adds padding inside the input boxes
          },
          buttons: [{
              type: 'week',
              count: 1,
              text: '1w'
          }, {
              type: 'month',
              count: 1,
              text: '1m'
          }, {
              type: 'month',
              count: 2,
              text: '2m'
          }, {
              type: 'month',
              count: 6,
              text: '6m'
          }, {
              type: 'year',
              count: 1,
              text: '1y'
          }, {
              type: 'all',
              text: 'All'
          }],
          buttonTheme: {
            fill: 'none',
            stroke: 'none',
            'stroke-width': 0,
            style: {
              color: '#000000',
              fontWeight: 'bold'
            },
            states: {
              hover: {
                fill: '#5D5D5E',
                style: {
                  color: 'white'
                }
              },
              select: {
                fill: '#000000',
                style: {
                  color: 'white'
                }
              }
            }
          }
        },
        xAxis: {
          // type: 'datetime',
          lineColor: '#5D5D5E',
          type: 'datetime',
          tickPixelInterval: 200,
          // dateTimeLabelFormats: {
          //     day: '%e/%m/%Y' // Day format
          // },
          labels: {
              style: {
                color: '#000000'
              },
              // format: '{value:%e/%m/%Y}', // Display date format
              rotation: 0, // Set rotation to 0 for no tilt
              align: 'center' // Align labels to center under the tick
          },
        },
        // yAxis: {
        //   allowDecimals: false,
        //   // reversed: true,
        //   min: 1,
        //   minRange: 1,
        //   // startOnTick: false,
        //   // endOnTick: false,
        //   title: {
        //     text: 'อันดับ/วันเวลา'
        //   },
        //   startOnTick: false,
        //   endOnTick: false,
        //   gridLineColor: 'rgba(0, 0, 0, 0.05)',
        //   gridLineWidth: 1,
        //   gridLineDashStyle: 'Solid', // This is already the default, but specifying it for clarity
        //   labels: {
        //     style: {
        //       color: '#000000'
        //     }
        //   }
        // },
        global: {
          useUTC: false
        },
      },
      loading_add_domain: false
  }),
  created () {
    // Set locale
    this.locale = (this.$auth.check()) ? this.$auth.user().locale: Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
    this.moment.locale(this.locale.substr(0,2))
    if(!this.$auth.user().page_dashboard){
      this.$router.push({
        name: 'settings.profile',
      })
    }
    
    this.$root.$on('switchThemeChart', () => {
      this.SetThemeChart()
    });

    this.$root.$on('switchThemeChartRefreshBacklink', () => {
      this.initDashboard('backlink')
    });
    
    
    //this.status_active = true
    this.has_tab = true
    this.has_all = true
    this.has_rank_group = true
    this.has_rank_absolute = true
    this.has_rank_group_domain = true
    this.has_rank_absolute_domain = true
    this.tab_table_keyword_rank = 'all'    
    this.tab_chart_ranking_active = 'all'
    this.tab_keyword_performance = 'all'
    this.tab_table_keyword_performance_historical = 'all'
    if(this.$auth.user().json_disable_column && this.$auth.user().json_disable_column.keyword){
      let column_keyword = this.$auth.user().json_disable_column.keyword

      let count = 5
      if(column_keyword.indexOf("rank_absolute_domain_url") != -1){
        this.has_rank_absolute_domain = false
        count--
      }
      else{
        this.tab_chart_ranking_active = 'rank_absolute_domain'
        this.tab_keyword_performance = 'rank_absolute_domain'
        this.tab_table_keyword_performance_historical = 'rank_absolute_domain'
      }

      if(column_keyword.indexOf("rank_group_domain_url") != -1){
        this.has_rank_group_domain = false
        count--
      }else{
        this.tab_chart_ranking_active = 'rank_group_domain'
        this.tab_keyword_performance = 'rank_group_domain'
        this.tab_table_keyword_performance_historical = 'rank_group_domain'
      }

      if(column_keyword.indexOf("rank_absolute_url") != -1){
        this.has_rank_absolute = false
        count--
      }
      else{
        this.tab_chart_ranking_active = 'rank_absolute'
        this.tab_keyword_performance = 'rank_absolute'
        this.tab_table_keyword_performance_historical = 'rank_absolute'
      }

      if(column_keyword.indexOf("rank_group_url") != -1){
        this.has_rank_group = false
        count--
      }
      else{
        this.tab_chart_ranking_active = 'rank_group'
        this.tab_keyword_performance = 'rank_group'
        this.tab_table_keyword_performance_historical = 'rank_group'
      }

      if(column_keyword.indexOf("best_ranking") != -1){
        this.has_all = false
        count--
      }
      else{
        this.tab_chart_ranking_active = 'all'
        this.tab_keyword_performance = 'all'
        this.tab_table_keyword_performance_historical = 'all'
      }
      if(count <= 1){
        this.has_tab = false
      }
    }
    this.open()
  },
  methods: {
    copyStringToClipboard,
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    getURLParameter(name) {
			return this.encodeDecodeURL((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
		},
    encodeDecodeURL(url) {
      if(!url){
          return ''
      }
      const parts = url.split('/');
      const decodedParts = parts.map(part => {
        try {
          // พยายามดีโค้ดแต่ละส่วนของ URL
          return decodeURIComponent(part);
        } catch {
          // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
          return part;
        }
      });
      // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
      return decodedParts.join('/');
    },
    open(){ 
      this.getDataToken()
    },
    SetThemeChart(){
      if(this.$vuetify.theme.dark){  
          this.chart_ranking_active.chart.backgroundColor = '#232425'
          this.chart_ranking_active.xAxis.labels.style.color = '#FFF'
          this.chart_ranking_active.yAxis.labels.style.color = '#FFF'
          this.chart_ranking_active.rangeSelector.buttonTheme.style.color = '#FFF'
          this.chart_ranking_active.rangeSelector.inputStyle.color = '#FFF'

          this.chart_backlink.chart.backgroundColor = '#232425'
          this.chart_backlink.xAxis.labels.style.color = '#FFF'
          // this.chart_backlink.yAxis.labels.style.color = '#FFF'
          this.chart_backlink.rangeSelector.buttonTheme.style.color = '#FFF'
          this.chart_backlink.rangeSelector.inputStyle.color = '#FFF'

          
          this.option_keyword_performance.chart.backgroundColor = '#232425'
          this.option_keyword_performance.xAxis.labels.style.color = '#FFF'
          this.option_keyword_performance.yAxis.labels.style.color = '#FFF'

          this.option_keyword_performance_bar.chart.backgroundColor = '#232425'
          this.option_keyword_performance_bar.xAxis.labels.style.color = '#FFF'
          this.option_keyword_performance_bar.yAxis.labels.style.color = '#FFF'
        }
        else{                      
          this.chart_ranking_active.chart.backgroundColor = '#FFF'             
          this.chart_ranking_active.xAxis.labels.style.color = '#000'
          this.chart_ranking_active.yAxis.labels.style.color = '#000'
          this.chart_ranking_active.rangeSelector.buttonTheme.style.color = '#000'
          this.chart_ranking_active.rangeSelector.inputStyle.color = '#000'

          this.chart_backlink.chart.backgroundColor = '#FFF'             
          this.chart_backlink.xAxis.labels.style.color = '#000'
          // this.chart_backlink.yAxis.labels.style.color = '#000'
          this.chart_backlink.rangeSelector.buttonTheme.style.color = '#000'
          this.chart_backlink.rangeSelector.inputStyle.color = '#000'

          this.option_keyword_performance.chart.backgroundColor = '#FFF'             
          this.option_keyword_performance.xAxis.labels.style.color = '#000'
          this.option_keyword_performance.yAxis.labels.style.color = '#000'

          this.option_keyword_performance_bar.chart.backgroundColor = '#FFF'             
          this.option_keyword_performance_bar.xAxis.labels.style.color = '#000'
          this.option_keyword_performance_bar.yAxis.labels.style.color = '#000'
          
        }
    },
    getDataToken(){
      this.loading_token = true
      let enterprise_uuid = ''
      if(this.$route.query && this.$route.query.enterprise_uuid){
        enterprise_uuid = this.$route.query.enterprise_uuid
      }
      this.axios.post('/tracking/dashboard', {
        enterprise_uuid: enterprise_uuid,
      })
      .then(res => {

        this.token_dashboard = this.$init.config.schemeAndHost + '/v1/dashboard?token=' + res.data.token_dashboard
        
        let all_keyword = res.data.all_keyword
        let array_keyword = []
        for(let e of all_keyword){
          array_keyword.push({
                active: true,
                show: true,
                value: e.keyword,
                text: e.keyword
          })
        }
        this.array_keyword = array_keyword
        for(let type of this.type_filters){
          
          let filters = {}
          //if(type == 'table_keyword_rank'){
            // filters['status_active'] = {
            //   search: '',
            //   active: true,
            //   name: 'status_active',
            //   text: this.$t('active_keyword'),
            //   items: [
            //     {
            //       active: true,
            //       show: true,
            //       value: 1,
            //       text: this.$t('active')
            //     },
            //     {
            //       active: true,
            //       show: true,
            //       value: 0,
            //       text: this.$t('unactive')
            //     },
            //   ]
            // }
          //}
          
          if(type == 'backlink'){
            filters['status_serpclick'] = {
              search: '',
              active: true,
              name: 'status_serpclick',
              text: this.$t('type'),
              items: [
                {
                  active: true,
                  show: true,
                  value: 0,
                  text: this.$t('backlink')
                },
                {
                  active: true,
                  show: true,
                  value: 1,
                  text: this.$t('serpclick')
                },
              ]
            }
          }
          
          if(type == 'table_keyword_rank'){
            filters['search_engine'] = {
              search: '',
              active: true,
              name: 'search_engine',
              text: this.$t('search_engine'),
              items: [
                {
                  active: true,
                  show: true,
                  value: 'google',
                  text: this.$t('google')
                },
                {
                  active: true,
                  show: true,
                  value: 'bing',
                  text: this.$t('bing')
                },
              ]
            }
          }
          else{
            filters['search_engine'] = {
              search: '',
              active: true,
              name: 'search_engine',
              text: this.$t('search_engine'),
              items: [  
                {
                  active: true,
                  show: true,
                  value: 'google',
                  text: this.$t('google')
                },
                {
                  active: true,
                  show: true,
                  value: 'bing',
                  text: this.$t('bing')
                },
                {
                  active: true,
                  show: true,
                  value: 'yahoo',
                  text: this.$t('yahoo')
                },
                {
                  active: true,
                  show: true,
                  value: 'youtube',
                  text: this.$t('youtube')
                },
                {
                  active: true,
                  show: true,
                  value: 'baidu',
                  text: this.$t('baidu')
                },
                {
                  active: true,
                  show: true,
                  value: 'naver',
                  text: this.$t('naver')
                },
                {
                  active: true,
                  show: true,
                  value: 'seznam',
                  text: this.$t('seznam')
                },
              ]
            }
          }

          filters['device'] = {
            search: '',
            active: true,
            name: 'device',
            text: this.$t('device'),
            items: [
              {
                active: true,
                show: true,
                value: 'desktop',
                text: this.$t('desktop')
              },
              {
                active: true,
                show: true,
                value: 'mobile',
                text: this.$t('mobile')
              },
            ]
          }

          filters['keyword'] = {
              search: '',
              active: true,
              name: 'keyword',
              text: this.$t('keyword'),
              items: JSON.parse(JSON.stringify(array_keyword))
          }
          this.filters[type] = filters
          let historical_data = 1
          // if(type == 'chart_ranking'){
          //   historical_data = 1
          // }
          // else if(type == 'backlink'){
          //   historical_data = 1
          // }
          this.historical_data[type] = historical_data
          if(type == 'keyword_performance' || type == 'table_keyword_performance_historical'){
            this.week_date[type] = 'week'  
          }
        }
        //console.log(this.filters)
        // for(let type of this.type_filters){
        //   this.initDashboard(type)
        // }
        let post_average = {
          enterprise_uuid: ((this.$route.query && this.$route.query.enterprise_uuid) ? this.$route.query.enterprise_uuid : ''),
          status_active: 'all',
          search_engine: 'all',
          array_keyword: 'all',
          device: 'all',
          status_serpclick: 'all',
          historical_data: 1
        }

        let post = {
          enterprise_uuid: ((this.$route.query && this.$route.query.enterprise_uuid) ? this.$route.query.enterprise_uuid : ''),
          status_active: 'all',
          search_engine: 'all',
          array_keyword: 'all',
          device: 'all',
          status_serpclick: 'all',
          historical_data: 1,
          week_date: 1
        }
        this.initDashboardRankingAverage(post_average)
        this.initDashboardAll(post)
        this.initDashboard('backlink')
      })
      .catch(err => {
        if(this.count_api <= 3){
          this.count_api++
          this.getDataToken()
        }
        if (err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => this.loading_token = false)
      
    },
    refreshToken(){
      this.loading_refresh_token = true
      this.axios.post('/tracking/dashboard/refresh-token', {
        enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid) ? this.$route.query.enterprise_uuid : ''
      })
      .then(res => {
        //console.log(res.data)
        this.token_dashboard = this.$init.config.schemeAndHost + '/v1/dashboard?token=' + res.data.token_dashboard
      })
      .catch(err => {
        if (err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => this.loading_refresh_token = false)
    },
    initDashboard(type){
      let filters = this.filters[type]
      let status_active = 'all'
      let search_engine = 'all'
      let keyword = 'all'
      let device = 'all'
      let status_serpclick = 'all'
      let array_status_active = []
      //if(type == 'table_keyword_rank'){
        // for(let e of filters.status_active.items){
        //   if(e.active){
        //     array_status_active.push(e.value)
        //   }
        // }
        // if(!array_status_active.length){
        //   status_active = 1
        // }
        // else{
        //   if(array_status_active.length == 2){
        //     status_active = 'all'
        //   }
        //   else{
        //     status_active = array_status_active[0]
        //   }
        // }
      //}  
      let array_status_serpclick = []
      if(type == 'backlink'){
        for(let e of filters.status_serpclick.items){
          if(e.active){
            array_status_serpclick.push(e.value)
          }
        }
        if(!array_status_serpclick.length){
          status_serpclick = 1
        }
        else{
          if(array_status_serpclick.length == 2){
            status_serpclick = 'all'
          }
          else{
            status_serpclick = array_status_serpclick[0]
          }
        }
      } 

      let array_search_engine = []
      for(let e of filters.search_engine.items){
        if(e.active){
          array_search_engine.push(e.value)
        }
      }
      if(!array_search_engine.length){
        search_engine = 'all'
      }
      else{
        if(array_search_engine.length == filters.search_engine.items.length){
          search_engine = 'all'
        }
        else{
          search_engine = JSON.stringify(array_search_engine)
        }
      }

      let array_device = []
      for(let e of filters.device.items){
        if(e.active){
          array_device.push(e.value)
        }
      }
      if(!array_device.length){
        device = 'all'
      }
      else{
        if(array_device.length == filters.device.items.length){
          device = 'all'
        }
        else{
          device = JSON.stringify(array_device)
        }
      }
      

      let array_keyword = []
      for(let e of filters.keyword.items){
        if(e.active){
          array_keyword.push(e.value)
        }
      }
      if(!array_keyword.length){
        keyword = 'all'
      }
      else{
        if(array_keyword.length == filters.keyword.items.length){
          keyword = 'all'
        }
        else{
          keyword = JSON.stringify(array_keyword)
        }
      }
      //console.log(this.week_date[type])
      let post = {
        enterprise_uuid: ((this.$route.query && this.$route.query.enterprise_uuid) ? this.$route.query.enterprise_uuid : ''),
        status_active: status_active,
        search_engine: search_engine,
        array_keyword: keyword,
        device: device,
        status_serpclick: status_serpclick,
        historical_data: this.historical_data[type],
        week_date: (this.week_date[type] && this.week_date[type] == 'week' ? 1 : 0),
      }
      
      if(type == 'table_keyword_rank'){
        this.getDataKeywordSearchVolume(post)  
      }
      else if(type == 'chart_ranking'){
        if(this.ranking_avg){
          this.initDashboardRankingAverage(post)
        }
        else{
          this.getDataKeywordRanking(post)
        }
        
      }
      else if(type == 'keyword_performance'){
        this.getDataKeywordPerformance(post)
      }
      else if(type == 'table_keyword_performance_historical'){
        this.getDataKeywordPerformanceHistorical(post)
      }
      else if(type == 'backlink'){
        this.getDataBacklink(post)
      }
    },
    getItemClass(item){
         // here define your logic
         //console.log(item)
         return ''
    },
    addDomain() {
      const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,})$/gi;
      if(this.input_domain && pattern.test(this.input_domain)){
        this.loading_add_domain = true
        this.axios.post('/tracking/dashboard/add-domain', {
          domain: this.input_domain,
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid) ? this.$route.query.enterprise_uuid : ''
        })
        .then(res => {
          //console.log(res.data)
          this.array_domain.push(res.data.domain)
          this.input_domain = ""
          this.has_insert_rival = false
          this.initDashboard('table_keyword_rank')
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading_add_domain = false)
        
      }
      else{
        this.$root.$snackbar(this.$t('invalid_website'))
      }
    },
    removeDomain(uuid,index) {
      //console.log(index) 
      let reason = false
      this.$root.$confirm(this.$t('confirm'), this.$t('confirm_delete_domain'),{
        color: 'primary',
        width: 360,
        zIndex: 200
      },reason)
      .then((confirm) => {
        if (confirm.confirm) {
          this.loading_add_domain = true
          this.axios.post('/tracking/dashboard/delete-domain', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid) ? this.$route.query.enterprise_uuid : ''
          })
          .then(res => {
            //console.log(res.data)
            this.array_domain.splice(index, 1)
            this.initDashboard('table_keyword_rank')
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading_add_domain = false)
        }
      })
      
      
      // let idx = this.attrArr.indexOf(id)
      // this.attrArr.splice(idx, 1)
      // this.attrArr = [...this.attrArr]
     
    },
    filterDashboard(type){
      //console.log(this.filters)
      this.$root.$filters_dashboard(this.filters[type],type,this.ranking_avg,this.historical_data[type],this.week_date[type])
      .then((confirm) => {
        if (confirm.confirm) {
          //console.log(confirm.filters)
          //console.log(this.filters)
          //console.log(type)
          this.historical_data[type] = confirm.historical_data
          this.week_date[type] = confirm.week_date
          this.ranking_avg = confirm.ranking_avg
          this.filters[type] = JSON.parse(JSON.stringify(confirm.filters))
          //console.log(this.filters)
          this.initDashboard(type)
        }
      })
    },
    initKeywordPerformance(keyword_performance,months){
      let array = []
      let data_keyword = {}
      for(let e of this.con){
        array[e] = []
      }
      //console.log(array) 
      //console.log(dashboard.keyword_performance) 
      let array_type = ['all','rank_absolute','rank_absolute_domain','rank_group','rank_group_domain']
      for(let data of keyword_performance){

        for(let month of months){
          
          if(!data_keyword[month.text]){
            data_keyword[month.text] = {
              keyword: [],
            }
            for(let type of array_type){
              data_keyword[month.text][type] = {
                top_3: {
                  data: [],
                  value: 0
                },
                page_1: {
                  data: [],
                  value: 0
                },
                page_2: {
                  data: [],
                  value: 0
                },
                page_3: {
                  data: [],
                  value: 0
                },
                top_100: {
                  data: [],
                  value: 0
                },
                no_rank: {
                  data: [],
                  value: 0
                },
              }
            }
          }

          let data_month = {
            keyword: data.keyword,
            url: data.url,
          }
          
          if(data.performance){
            for(let type of array_type){
              data_month[type] = data.performance[type][month.date]
            }
            
          }
          data_keyword[month.text].keyword.push(data_month)
        }
      }
      //console.log(data_keyword)
      
      for(let i in data_keyword){
        
        for(let keyword of data_keyword[i].keyword){
          for(let type of array_type){
            if(!keyword[type]){
              // no ranking
            }
            else if(keyword[type] <= 3){
              data_keyword[i][type]['top_3']['value']++
              data_keyword[i][type]['top_3']['data'].push({
                value: keyword[type],
                keyword: keyword['keyword'],
                url: keyword['url'],
              })
              data_keyword[i][type]['page_1']['value']++
              data_keyword[i][type]['page_1']['data'].push({
                value: keyword[type],
                keyword: keyword['keyword'],
                url: keyword['url'],
              })
            }
            else if(keyword[type] <= 10){
              data_keyword[i][type]['page_1']['value']++
              data_keyword[i][type]['page_1']['data'].push({
                value: keyword[type],
                keyword: keyword['keyword'],
                url: keyword['url'],
              })
            }
            else if(keyword[type] <= 20){
              data_keyword[i][type]['page_2']['value']++
              data_keyword[i][type]['page_2']['data'].push({
                value: keyword[type],
                keyword: keyword['keyword'],
                url: keyword['url'],
              })
            }
            else if(keyword[type] <= 30){
              data_keyword[i][type]['page_3']['value']++
              data_keyword[i][type]['page_3']['data'].push({
                value: keyword[type],
                keyword: keyword['keyword'],
                url: keyword['url'],
              })
            }
            else if(keyword[type] <= 100){
              data_keyword[i][type]['top_100']['value']++
              data_keyword[i][type]['top_100']['data'].push({
                value: keyword[type],
                keyword: keyword['keyword'],
                url: keyword['url'],
              })
            }
            else{
              data_keyword[i][type]['no_rank']['value']++
              data_keyword[i][type]['no_rank']['data'].push({
                value: keyword[type],
                keyword: keyword['keyword'],
                url: keyword['url'],
              })
            } 
          }
          
        }

        for(let keyword of data_keyword[i].keyword){
          for(let type of array_type){
            for(let con of this.con){
              let array = data_keyword[i][type][con]['data']
              if(array.length){
                let sortedArray = array.sort((a, b) => a.value - b.value);
                let topTen = sortedArray.slice(0, 10);
                data_keyword[i][type][con]['data'] = topTen
              }
            } 
          }
        }
        //console.log(data_keyword[i])
      }
      //console.log(data_keyword)
      return data_keyword
    },
    setTableKeyword(search_engine){
      this.tab_table_keyword_rank = search_engine
      this.InitTableRank()
    },
    setChartRankingActive(rank){
      this.tab_chart_ranking_active = rank

      if(this.ranking_avg){
        this.InitChartRankAverage()
      }
      else{
        this.InitChartRank()
      }
      //this.InitChartRank()
      
    },
    setKeywordPerformance(rank){ 
      this.tab_keyword_performance = rank
      this.InitChartKeywordPerformance()    
      //this.InitChartKeywordPerformanceHistorical()
    },
    setTableKeywordPerformanceHistorical(rank){
      this.tab_table_keyword_performance_historical = rank
      this.InitTableKeywordPerformanceHistorical()
    },
    InitChartRankAverage(){
      let data = this.report_ranking
      this.chart_ranking_active.yAxis.title.text = this.$t('ranking_date')
      let series = []
      let that = this
      for(let device in data){
        for(let search_engine in data[device]){
          let array = [];
          for(let e of data[device][search_engine]){
            // let sub = [];
            // sub.push(this.moment(e.date).unix()*1000);
            // sub.push(e[this.tab_chart_ranking_active+'_avg']);
            // array.push(sub);
            let text = ''
            if(e['logs_'+this.tab_chart_ranking_active]){
              text += '<b>'+this.$t('10_best_ranking')+'</b><br>'
              for(let e of e['logs_'+this.tab_chart_ranking_active]){
                text += e.keyword + ' ('+e.url+')' + ' '+this.$t('ranking')+': <b><span style="color:#4CAF50">'+e[this.tab_chart_ranking_active]+'</span></b><br>'
              }
            }
            array.push({
              x:this.moment(e.date).unix()*1000,
              y:e[this.tab_chart_ranking_active+'_avg'],
              log: text,
            });
          }
          
          let name = this.$t(search_engine) + ' '+(device == 'desktop' ? '💻' : '📱') + ' ' + this.$t('ranking_avg')
          series.push({
            name: name,
            data : array,
            // tooltip:{
            //   formatter: function () {
            //     const point = this.points[0];
            //     let tooltip =  that.moment.unix(point.x/1000).format("DD/MM/YYYY") + ' <br><br>'
            //     tooltip += point.series.name
            //     if(point.point && point.point.log && point.point.log.length){
            //       tooltip += '<br><br>'
            //       for(let e of point.point.log){
            //         tooltip += e.keyword + ' ('+e.url+')' + ' '+that.$t('ranking')+': <span style="color:#4CAF50">'+e[that.tab_chart_ranking_active]+'</span><br>'
            //       }
            //     }  
            //     return tooltip
            //   }
            // }
          });
        }
      }

      this.has_chart_ranking_active = false
      if(series.length){
        this.has_chart_ranking_active = true
      }

      // this.chart_ranking_active.tooltip = {
      //   useHTML: true,
      //   style: {
      //       width: '300px',  
      //       height: '1000px',
      //   },
      //   formatter: function () {
      //     //console.log(this)
      //     //const point = this.points[0];
      //     let tooltip = that.moment.unix(this.points[0].x/1000).format("DD/MM/YYYY") + ' <br>'
      //     for(let point of this.points){
      //       //console.log(point)
      //       tooltip += point.series.name + ' '+that.$t('ranking_avg')+': <span style="color:#4CAF50">'+point.y+'</span><br>'
      //       if(point.point && point.point.log && point.point.log.length){
      //         //tooltip += '<br><br>'
      //         //console.log(point.point.log[0].keyword)
      //         tooltip += point.point.log[0].keyword  + ' ('+point.point.log[0].url+')' + ' '+that.$t('ranking')+': <span style="color:#4CAF50">'+point.point.log[0][that.tab_chart_ranking_active]+'</span><br>'
      //         tooltip += point.point.log[1].keyword  + ' ('+point.point.log[1].url+')' + ' '+that.$t('ranking')+': <span style="color:#4CAF50">'+point.point.log[1][that.tab_chart_ranking_active]+'</span><br>'
      //         // tooltip += point.point.log[2].keyword  + ' ('+point.point.log[2].url+')' + ' '+that.$t('ranking')+': <span style="color:#4CAF50">'+point.point.log[2][that.tab_chart_ranking_active]+'</span><br>'
      //         //tooltip += point.point.log[3].keyword  + ' ('+point.point.log[3].url+')' + ' '+that.$t('ranking')+': <span style="color:#4CAF50">'+point.point.log[3][that.tab_chart_ranking_active]+'</span><br>'
      //         // for(let e of point.point.log){
      //         //   tooltip += e.keyword + ' ('+e.url+')' + ' '+that.$t('ranking')+': <span style="color:#4CAF50">'+e[that.tab_chart_ranking_active]+'</span><br>'
      //         // }
      //       } 
      //       tooltip +=''
      //     }
          
      //     //console.log(tooltip)
          
      //     return tooltip
          
      //     //return 'วันที่: '+point.x+ ' '+ point.y
      //   },
      //   shared: true,
      //   crosshairs: true,
      // }
      this.chart_ranking_active.tooltip = {
          headerFormat: '<span>{point.x:%d/%m/%Y}</span><br/>', 
          pointFormat: '<span style="color:{series.color}">' +
              '{series.name}</span>: <b>{point.y}</b> ' +
              '<br>{point.log}<br/>',
          split: false,
          shared: false,  // ตั้งค่านี้เป็น false เพื่อให้ tooltip ไม่แสดงขึ้นพร้อมกันสำหรับทุกเส้น
          crosshairs: true  // เปิดใช้งาน crosshairs เพื่อช่วยในการติดตามเส้นบนกราฟ
      }
      this.chart_ranking_active.series = series
    },
    InitChartRank() {
      this.chart_ranking_active.yAxis.title.text = this.$t('ranking_date')
      let series = []
      for(let e of this.report_ranking){
        let array = [];
        for(let v of e.ranking){
          let sub = [];
          sub.push(this.moment(v.date_created).unix()*1000);
          sub.push(v[this.tab_chart_ranking_active]);
          array.push(sub);
        } 
        series.push({
          name: e.keyword + ' (' + this.encodeDecodeURL(e.url) + ') <span style="color:'+(e.status_active ? '#4CAF50' : '#F44336')+'">'+(e.status_active ? this.$t('active') : this.$t('unactive'))+'</span> '+(e.device == 'desktop' ? '💻' : '📱'),
          data : array
        });
      }
      this.has_chart_ranking_active = false
      if(series.length){
        this.has_chart_ranking_active = true
      }
      this.chart_ranking_active.tooltip = {
        headerFormat: '<span>{point.x:%d/%m/%Y %H:%M}</span><br/>', 
        split: false,
        shared: false,  // ตั้งค่านี้เป็น false เพื่อให้ tooltip ไม่แสดงขึ้นพร้อมกันสำหรับทุกเส้น
        crosshairs: true  // เปิดใช้งาน crosshairs เพื่อช่วยในการติดตามเส้นบนกราฟ
      }
      
      this.chart_ranking_active.series = series
    },
    InitTableRank() {
      //let dashboard = this.dashboard
      let header = [
        { text: this.$t('search_engine'), value: 'search_engine' },
        { text: this.$t('keyword'), value: 'keyword' },
        { text: this.$t('locale'), value: 'locale' },
        { text: this.$t('language'), value: 'language' },
        // { text: this.$t('google_result'), value: 'googleresult' },
        { text: this.$t('search_volume'), value: 'search_volume' },
        { text: this.$t('adwords_score'), value: 'adwords_score' },
        { text: this.$t('adwords_index'), value: 'adwords_index' },
        { text: this.$t('cpc'), value: 'cpc' },
        { text: this.$t('low_top_of_page_bid'), value: 'low_top_of_page_bid' },
        { text: this.$t('high_top_of_page_bid'), value: 'high_top_of_page_bid' },
      ]
      for(let e of this.array_domain){
				header.push({
          text: e.domain,
          value: e.uuid,
        }) 
			}
      header.push({
        text: this.$t('monthly_search'),
        value: 'monthly_search',
      })
      
      
      let dessert = []
      let no = 1
      for(let data of this.keyword_search_volume){
        if(this.tab_table_keyword_rank == 'google' && data.search_engine == 'bing'){
          continue;
        }
        else if(this.tab_table_keyword_rank == 'bing' && data.search_engine == 'google'){
          continue;
        }

        let search_volume = '-'
        let adwords_score = '-'
        let adwords_index = '-'
        let cpc = '-'
        let low_top_of_page_bid = '-'
        let high_top_of_page_bid = '-'
        let monthly_search = []

        let res_monthly_searches = []
        if(data.search_volume){

          search_volume = data.search_volume.search_volume ? data.search_volume.search_volume : '-'
          adwords_score = data.search_volume.competition ? data.search_volume.competition : '-'
          adwords_index = data.search_volume.competition_index ? data.search_volume.competition_index : '-'
          cpc = data.search_volume.cpc ? data.search_volume.cpc : '-'
          low_top_of_page_bid = data.search_volume.low_top_of_page_bid ? data.search_volume.low_top_of_page_bid : '-'
          high_top_of_page_bid = data.search_volume.high_top_of_page_bid ? data.search_volume.high_top_of_page_bid : '-'
          if(data.search_volume.monthly_searches){
            for(let monthly_searches of data.search_volume.monthly_searches.reverse()){
              //res_monthly_searches.push(monthly_searches.search_volume)
              // res_monthly_searches.push({
              //   x: monthly_searches.year + '/' + monthly_searches.month,
              //   y: monthly_searches.search_volume,
              // })
              res_monthly_searches.push([monthly_searches.month + '/' + monthly_searches.year,monthly_searches.search_volume])
            }
          }
          
        }
        monthly_search = res_monthly_searches

        let data_keyword = {
          search_engine: data.search_engine,
          keyword: data.keyword,
          locale: data.locale_name,
          language: data.language_name,
          search_volume: search_volume,
          adwords_score: adwords_score,
          adwords_index: adwords_index,
          cpc: cpc,
          low_top_of_page_bid: low_top_of_page_bid,
          high_top_of_page_bid: high_top_of_page_bid,
          monthly_search: monthly_search,
        }
        
        
        for(let domain of this.array_domain){
          let data_domain = data.domain_competition_match.find(item => item.dashboard_domain_uuid == domain.uuid) 
          let rank = '-'
					//let class_color = 'background_red'
          let class_color = ''
          if(data_domain){
            if(data_domain.is_match && data_domain.data.length){
              rank = data_domain.data[0].rank_group
              //class_color = 'background_green'
            }
          }
          data_keyword[domain.uuid] = '<div class="td_monthly_search '+class_color+'">'+rank+'<div>'
				}
				dessert.push(data_keyword)
        no++
			}
      
      // console.log(header)
      // console.log(dessert)
      this.has_table_keyword_rank = false
      if(dessert.length){
        this.has_table_keyword_rank = true
      }

      this.headers_rank = header
      this.desserts_rank = dessert
    },
    handleMouseOver(column) {
      // console.log(this.sortedBy)
      // console.log(column)
      this.hoveredColumn = column;
    },
    handleMouseLeave() {
      this.hoveredColumn = null;
    },
    updateSortBy(newSortBy) {
      //console.log(newSortBy)
      this.sortedBy = newSortBy;
    },
    updateSortDesc(newSortDesc) {
      //console.log(newSortDesc)
      this.sortedDesc = newSortDesc;
    },
    onSort({ sortBy, sortDesc }) {
      this.currentSortColumn = sortBy;
      this.isSortDesc = sortDesc;
      
      this.headers_rank = this.headers_rank.map(header => ({
        ...header,
        sortIcon: header.value === sortBy
          ? (sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up')
          : 'mdi-arrow-up-down'
      }));
    },
    InitTableKeywordPerformanceHistorical() {             
      let keyword_performance = this.keyword_performance_historical
      let head_date = this.week_date['table_keyword_performance_historical'] && this.week_date['table_keyword_performance_historical'] == 'week' ? this.$t('month_week_year') : this.$t('month_year')
      let header = [
        { text: head_date, value: 'date' },
        { text: this.$t('total_keyword'), value: 'keyword_rank' },
        { text: this.$t('top_3'), value: 'top_3' },
        { text: this.$t('page_1'), value: 'page_1' },
        { text: this.$t('page_2'), value: 'page_2' },
        { text: this.$t('page_3'), value: 'page_3' },
        { text: this.$t('top_100'), value: 'top_100' },
        { text: this.$t('no_rank'), value: 'no_rank' },
        { text: this.$t('keyword_rank') + ' (%)', value: 'keyword_rank_percent' },
        { text: this.$t('page_1') + ' (%)', value: 'page_1_percent' },
      ]
      
      //let keyword_performance = dashboard.keyword_performance
      let dessert = []

      
      for(let i in keyword_performance){
        let rank_sum = 0
        let keyword_rank_percent = 0
				let page_1_percent = 0
				for(let e of this.con){
					if(keyword_performance[i][this.tab_table_keyword_performance_historical][e]){
            //console.log(keyword_performance[i][this.tab_table_keyword_performance_historical][e])
            if(e == 'top_3'){
              //rank_sum += keyword_performance[i][this.tab_table_keyword_performance_historical][e]
            }
            else if(e == 'page_1'){
              rank_sum += keyword_performance[i][this.tab_table_keyword_performance_historical][e]['value']
            }
						else{
              rank_sum += keyword_performance[i][this.tab_table_keyword_performance_historical][e]['value']
            }

            
						if((e == 'page_1') && keyword_performance[i][this.tab_table_keyword_performance_historical][e]){
							page_1_percent += keyword_performance[i][this.tab_table_keyword_performance_historical][e]['value']
						}
            
            if(e != 'top_3' && e != 'no_rank' && keyword_performance[i][this.tab_table_keyword_performance_historical][e]){
							keyword_rank_percent += keyword_performance[i][this.tab_table_keyword_performance_historical][e]['value']
						}
					}
				}
        // console.log(keyword_performance[i])
        // console.log('top_3')
        // console.log(keyword_performance[i][this.tab_table_keyword_performance_historical].top_3.value)
        // console.log('page_1')
        // console.log(keyword_performance[i][this.tab_table_keyword_performance_historical].page_1.value)
        // console.log('page_2')
        // console.log(keyword_performance[i][this.tab_table_keyword_performance_historical].page_2.value)
        // console.log('page_3')
        // console.log(keyword_performance[i][this.tab_table_keyword_performance_historical].page_3.value)
        // console.log('top_100')
        // console.log(keyword_performance[i][this.tab_table_keyword_performance_historical].top_100.value)
        // console.log('no_rank')
        // console.log(keyword_performance[i][this.tab_table_keyword_performance_historical].no_rank.value)
        // console.log(this.tab_table_keyword_performance_historical)
        // console.log(i)
        // console.log(rank_sum)
        // console.log(keyword_rank_percent)
        // console.log(page_1_percent) 
        dessert.push({
          date: i,
          keyword_rank: rank_sum ? rank_sum : '-',
          top_3: (keyword_performance[i][this.tab_table_keyword_performance_historical].top_3.value ? keyword_performance[i][this.tab_table_keyword_performance_historical].top_3.value : '-'),
          page_1: (keyword_performance[i][this.tab_table_keyword_performance_historical].page_1.value ? keyword_performance[i][this.tab_table_keyword_performance_historical].page_1.value : '-'),
          page_2: (keyword_performance[i][this.tab_table_keyword_performance_historical].page_2.value ? keyword_performance[i][this.tab_table_keyword_performance_historical].page_2.value : '-'),
          page_3: (keyword_performance[i][this.tab_table_keyword_performance_historical].page_3.value ? keyword_performance[i][this.tab_table_keyword_performance_historical].page_3.value : '-'),
          top_100: (keyword_performance[i][this.tab_table_keyword_performance_historical].top_100.value ? keyword_performance[i][this.tab_table_keyword_performance_historical].top_100.value : '-'),
          no_rank: (keyword_performance[i][this.tab_table_keyword_performance_historical].no_rank.value ? keyword_performance[i][this.tab_table_keyword_performance_historical].no_rank.value : '-'),
          keyword_rank_percent: rank_sum ? (parseInt(keyword_rank_percent/rank_sum*100) + ' %') : '-',
          page_1_percent: rank_sum ? (parseInt(page_1_percent/rank_sum*100) + ' %') : '-'
        })
			}
      
      this.has_table_keyword_performance_historical = false
      if(dessert.length){
        this.has_table_keyword_performance_historical = true
      }
      this.has_keyword_performance = false
      if(dessert.length){
        this.has_keyword_performance = true
      }
      // console.log(header)
      // console.log(dessert)
      this.headers_keyword_performance_historical = JSON.parse(JSON.stringify(header))
      this.desserts_keyword_performance_historical = JSON.parse(JSON.stringify(dessert))
    },
    extractTop10(data) {
      Object.keys(data).forEach(month => {
        const keywords = data[month].keyword;
        data[month].all_top_10 = [...keywords].filter(k => k.all > 0).sort((a, b) => a.all - b.all).slice(0, 10).map(k => ({ url: k.url, keyword: k.keyword, all: k.all }));
        data[month].rank_group_top_10 = [...keywords].filter(k => k.rank_group > 0).sort((a, b) => a.rank_group - b.rank_group).slice(0, 10).map(k => ({ url: k.url, keyword: k.keyword, rank_group: k.rank_group }));
        data[month].rank_absolute_top_10 = [...keywords].filter(k => k.rank_absolute > 0).sort((a, b) => a.rank_absolute - b.rank_absolute).slice(0, 10).map(k => ({ url: k.url, keyword: k.keyword, rank_absolute: k.rank_absolute }));
        data[month].rank_group_domain_top_10 = [...keywords].filter(k => k.rank_group_domain > 0).sort((a, b) => a.rank_group_domain - b.rank_group_domain).slice(0, 10).map(k => ({ url: k.url, keyword: k.keyword, rank_group_domain: k.rank_group_domain }));
        data[month].rank_absolute_domain_top_10 = [...keywords].filter(k => k.rank_absolute_domain > 0).sort((a, b) => a.rank_absolute_domain - b.rank_absolute_domain).slice(0, 10).map(k => ({ url: k.url, keyword: k.keyword, rank_absolute_domain: k.rank_absolute_domain }));
      });
      return data;
    },
    InitChartKeywordPerformance() {
      let categories = []
			let series = []
      let series_2 = []
			let array = []
      //console.log(this.extractTop10(this.keyword_performance))
      //let keyword_performance = this.extractTop10(this.keyword_performance)
      let keyword_performance = this.keyword_performance
      //let data_keyword = {}
			//let keyword = {}
      //let keyword_performance = dashboard.keyword_performance
			for(let e of this.con){
				array[e] = []
			}
      //console.log(keyword_performance)
      for(let i in keyword_performance){
        categories.push(i)
				//let old_array = JSON.parse(JSON.stringify(this.keyword_performance[i].keyword))
				//let new_array = old_array.sort( this.compareRanking );
				//keyword[i] = new_array
  
				for(let e of this.con){
					if(keyword_performance[i][this.tab_keyword_performance][e]){
						//array[e].push(keyword_performance[i][this.tab_keyword_performance][e])
            let text = ''
            if(keyword_performance[i][this.tab_keyword_performance][e]['data']){
              text += '<b>'+this.$t('10_best_ranking')+'</b><br>'
              for(let e of keyword_performance[i][this.tab_keyword_performance][e]['data']){
                text += e.keyword + ' ('+e.url+')' + ' '+this.$t('ranking')+': <b><span style="color:#4CAF50">'+e.value+'</span></b><br>'
              }
            }
            array[e].push({
              y: keyword_performance[i][this.tab_keyword_performance][e]['value'],
              log: text
            })
					}
					else{
						//array[e].push(0)
            array[e].push({
              y: 0
            })
					}
				}
			}
      //console.log(array)

			for(let e of this.con){
				// series.push({
				// 	label: this.$t(e),
        //   backgroundColor: this.color[e],
				// 	data: array[e]
				// })
        series_2.push({
					name: this.$t(e),
          backgroundColor: this.color[e],
					data: array[e]
				})
			}
      //series = series.reverse()
      
      //console.log(categories)
      //console.log(series_2)
      // this.chartDataKeywordPerformance.labels = categories
      // this.chartDataKeywordPerformance.datasets = series
      // let colors = ['#1abc9c', '#78d596', '#bceb94', '#ffff9d', '#fcc267', '#d43d51']
      this.option_keyword_performance.xAxis.categories = JSON.parse(JSON.stringify(categories))
      this.option_keyword_performance.series = JSON.parse(JSON.stringify(series_2))
      // this.option_keyword_performance = {
      //     chart: {
      //         type: 'column'
      //     },
      //     colors: colors,
      //     credits: {
      //       enabled: false
      //     },
      //     title: false,
      //     xAxis: {
      //         categories: JSON.parse(JSON.stringify(categories))
      //     },
      //     yAxis: {
      //         min: 0,
      //         title: false,
      //         stackLabels: {
      //             enabled: true
      //         }
      //     },
      //     legend: {
      //         align: 'center',
      //         x: 0,
      //         verticalAlign: 'top',
      //         y: 0,
      //         floating: true,
      //         backgroundColor: 'white',
      //         borderColor: '#CCC',
      //         borderWidth: 1,
      //         shadow: false
      //     },
      //     exporting: {
      //       buttons: {
      //           contextButton: {
      //               symbol: 'menu',
      //               align: 'right', 
      //               verticalAlign: 'top', 
      //               y: -3 
      //           },
      //       }
      //     },
      //     tooltip: {
      //         headerFormat: '<b>{point.x}</b><br/>',
      //         pointFormat: '{series.name}: {point.y}<br/>{point.log}<br/>'
      //         // pointFormat: '{series.name}: {point.y}<br/>'+this.$t('total')+': {point.stackTotal}<br>{point.log}<br/>'
      //     },
      //     plotOptions: {
      //         column: {
      //             stacking: 'normal',
      //             dataLabels: {
      //                 enabled: true
      //             }
      //         }
      //     },
      //     series: JSON.parse(JSON.stringify(series_2))
      // }
      this.option_keyword_performance_bar.xAxis.categories = JSON.parse(JSON.stringify(categories))
      this.option_keyword_performance_bar.series = JSON.parse(JSON.stringify(series_2))
      // this.option_keyword_performance_bar = {
      //     chart: {
      //         type: 'column'
      //     },
      //     colors: colors,
      //     credits: {
      //       enabled: false
      //     },
      //     title: false,
      //     xAxis: {
      //         categories: JSON.parse(JSON.stringify(categories))
      //     },
      //     yAxis: {
      //         min: 0,
      //         title: false,
      //         stackLabels: {
      //             enabled: true
      //         }
      //     },
      //     legend: {
      //         align: 'center',
      //         x: 0,
      //         verticalAlign: 'top',
      //         y: 0,
      //         floating: true,
      //         backgroundColor: 'white',
      //         borderColor: '#CCC',
      //         borderWidth: 1,
      //         shadow: false
      //     },
      //     tooltip: {  
      //         headerFormat: '<b>{point.x}</b><br/>',
      //         pointFormat: '{series.name}: {point.y}<br/>{point.log}<br/>'
      //     },
      //     plotOptions: {
      //       column: {
      //           pointPadding: 0.2,
      //           borderWidth: 0
      //       }
      //     },
      //     series: JSON.parse(JSON.stringify(series_2))
      // }
    },
    changeTypeChart(type){
      this.type_chart_keyword_performance = type
      //this.InitChartKeywordPerformance()    
      //console.log(this.type_chart_keyword_performance)
    },
    InitChartKeywordPerformanceHistorical() {
      //let keyword_performance = dashboard.keyword_performance
      let categories = []
      let keyword_performance = this.keyword_performance
      console.log(this.keyword_performance)
			//let series = []
      //return false

			let array = []
			for(let i in keyword_performance){
				categories.push(i)
				//console.log(v)
				if(!array[i]){
					array[i] = []
					array[i]['Total'] = 0
					array[i]['RankedKWs'] = 0
					array[i]['Page1'] = 0
				}
				for(let e of this.con){
					if(keyword_performance[i][this.tab_keyword_performance][e]){
						array[i]['Total'] += keyword_performance[i][this.tab_keyword_performance][e]
						if(e != 'top_3' && e != 'no_rank'){
							array[i]['RankedKWs'] += keyword_performance[i][this.tab_keyword_performance][e]
						}
						if(e == 'top_3' || e == 'page_1'){
							array[i]['Page1'] += keyword_performance[i][this.tab_keyword_performance][e]
						}
					}
				}
			}
			//console.log(categories)
			//console.log(array)
			for(let v of categories){
				let Total = array[v]['Total']
				let RankedKWs = array[v]['RankedKWs']
				let Page1 = array[v]['Page1']
				array[v]['percent_ranked'] = RankedKWs ? parseFloat(((RankedKWs/Total)*100).toFixed(0)) : 0
				array[v]['percent_page_1'] = Page1 ? parseFloat(((Page1/Total)*100).toFixed(0)) : 0
			}
			//console.log(array)
			let data_RankedKWs = []
			let data_percent_RankedKWs = []
			let data_percent_Page1 = []
			for(let v of categories){
				data_RankedKWs.push(array[v]['RankedKWs'])
				data_percent_RankedKWs.push(array[v]['percent_ranked'])
				data_percent_Page1.push(array[v]['percent_page_1'])
			}
      //console.log(data_RankedKWs)
      //console.log(data_percent_RankedKWs)
      //console.log(data_percent_Page1)
      this.option_keyword_performance_historical = {
				chart: {
					zoomType: 'xy'
				},
				title: {
					text: this.$t('keyword_performance_historical'),
				},
				credits: {
					enabled: false
				},
				xAxis: [{
					categories: categories,
					crosshair: true
				}],   
				yAxis: [{ // Primary yAxis
					labels: {
						format: '{value} %',
						style: {
							// color: Highcharts.getOptions().colors[0]
						}
					},
					title: {
						text: this.$t('page_1') + ' (%)',
						style: {
							// color: Highcharts.getOptions().colors[0]
						}
					},
					opposite: true

				}, { // Secondary yAxis
					gridLineWidth: 0,
					title: {
						text: this.$t('keyword_rank'),
						style: {
							// color: Highcharts.getOptions().colors[5]
						}
					},
					labels: {
						style: {
							// color: Highcharts.getOptions().colors[5]
						}
					}

				}, { // Tertiary yAxis
					gridLineWidth: 0,
					title: {
						text: this.$t('keyword_rank') + ' (%)',
						style: {
							// color: Highcharts.getOptions().colors[6]
						}
					},
					labels: {
						format: '{value} %',
						style: {
							// color: Highcharts.getOptions().colors[6]
						}
					},
					opposite: true
				}],
				tooltip: {
					shared: true
				},
				legend: {
					layout: 'vertical',
					align: 'left',
					x: 80,
					verticalAlign: 'top',
					y: 55,
					floating: true,
					// backgroundColor:Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
				},
				series: [{
					name: this.$t('keyword_rank'),
					type: 'column',
					yAxis: 1,
					data: data_RankedKWs,
					// color: Highcharts.getOptions().colors[0]
				}, {
					name: this.$t('keyword_rank') + ' (%)',
					type: 'spline',
					yAxis: 2,
					data: data_percent_RankedKWs,
					// color: Highcharts.getOptions().colors[5],
					tooltip: {
						valueSuffix: ' %'
					},
					marker: {
						enabled: false
					},
					dashStyle: 'shortdot'
				}, {
					name: this.$t('page_1') + ' (%)',
					type: 'spline',
					data: data_percent_Page1,
					// color: Highcharts.getOptions().colors[4],
					tooltip: {
						valueSuffix: ' %'
					}
				}],
				responsive: {
					rules: [{
						condition: {
							maxWidth: 500
						},
						chartOptions: {
							legend: {
								floating: false,
								layout: 'horizontal',
								align: 'center',
								verticalAlign: 'bottom',
								x: 0,
								y: 0
							},
							yAxis: [{
								labels: {
									align: 'right',
									x: 0,
									y: -6
								},
								showLastLabel: false
							}, {
								labels: {
									align: 'left',
									x: 0,
									y: -6
								},
								showLastLabel: false
							}, {
								visible: false
							}]
						}
					}]
				}
			}
    },
    initDashboardRankingAverage(post){
      this.loading_report_ranking = true
      this.axios.post('/tracking/dashboard/get-keyword-ranking-average', post)
      .then(res => {
        //console.log(res.data)
        this.report_ranking = res.data.data
        this.InitChartRankAverage()
      })
      .catch(err => {
        //console.log(err.response)
        this.SetThemeChart()
        if(this.count_api <= 3){
          this.count_api++
          this.initDashboardRankingAverage(post)
        }
        if (err && err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => {
        this.loading_report_ranking = false
      })
    },
    initDashboardAll(post){
      this.loading_keyword_search_volume = true
      // this.loading_report_ranking = true
      this.loading_keyword_performance = true
      this.loading_keyword_performance_historical = true
      
      this.axios.post('/tracking/dashboard/get-keyword-all', post)
      .then(res => {
        //console.log(res.data)
        this.SetThemeChart()
        this.count_api = 0

        let data = res.data.data

        this.array_domain = data.keyword_search_volume.array_domain
        //console.log(this.array_domain)
        this.keyword_search_volume = this.initDataKeywordSearchVolume(data.keyword_search_volume.data)
        this.InitTableRank()

        // this.report_ranking = data.keyword_ranking
        // this.InitChartRank()

        let keyword_performance = JSON.parse(JSON.stringify(data.keyword_performance))
        let date1 =  JSON.parse(JSON.stringify(data.date))
        let date2 =  JSON.parse(JSON.stringify(data.date.reverse()))
        //console.log(months1)
        //console.log(months2)
        this.keyword_performance = this.initKeywordPerformance(keyword_performance,date2)
        //console.log(data.months)
        this.keyword_performance_historical = this.initKeywordPerformance(keyword_performance,date1)
        //this.keyword_performance_historical = JSON.parse(JSON.stringify(new_data))
        // console.log(this.keyword_performance)
        //console.log(this.keyword_performance_historical)
        this.InitChartKeywordPerformance()
        this.InitTableKeywordPerformanceHistorical()

      })
      .catch(err => {
        //console.log(err.response)
        if(this.count_api <= 3){
          this.count_api++
          this.initDashboardAll(post)
        }
        if (err && err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => {
        this.loading_keyword_search_volume = false
        // this.loading_report_ranking = false
        this.loading_keyword_performance = false
        this.loading_keyword_performance_historical = false
      })
    },
    getDataKeywordSearchVolume(post){
      this.loading_keyword_search_volume = true
      this.axios.post('/tracking/dashboard/get-keyword-search-volume', post)
      .then(res => {
        //console.log(res.data)
        this.SetThemeChart()
        this.array_domain = res.data.data.array_domain
        this.keyword_search_volume = this.initDataKeywordSearchVolume(res.data.data.data)
        // let data_keyword_search_volume = res.data.data.data
        // let keyword_search_volume = []
        // for(let data in data_keyword_search_volume){
        //   //console.log(data_keyword_search_volume[data].details)  

        //   let domain_competition_match = []
        //   let search_volume = []

        //   for(let e of data_keyword_search_volume[data].details){
        //     domain_competition_match = e.domain_competition_match
        //     if(e.search_volume){
        //       search_volume = e.search_volume
        //       break;
        //     }
        //   }

        //   keyword_search_volume.push({
        //     keyword: data_keyword_search_volume[data].keyword,
        //     language: data_keyword_search_volume[data].language,
        //     language_name: data_keyword_search_volume[data].language_name,
        //     locale: data_keyword_search_volume[data].locale,
        //     locale_name: data_keyword_search_volume[data].locale_name,
        //     search_engine: data_keyword_search_volume[data].search_engine,
        //     domain_competition_match: domain_competition_match,
        //     search_volume: search_volume,
        //   })
        // }
        
        // this.keyword_search_volume = keyword_search_volume
        //console.log(keyword_search_volume)
        this.InitTableRank()
      })
      .catch(err => {
        if(this.count_api <= 3){
          this.count_api++
          this.initDashboardAll(post)
        }
        if (err && err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => this.loading_keyword_search_volume = false)
    },
    initDataKeywordSearchVolume(data){
      let data_keyword_search_volume = data
      let keyword_search_volume = []
      for(let data in data_keyword_search_volume){
        //console.log(data_keyword_search_volume[data].details)  

        let domain_competition_match = []
        let search_volume = []

        for(let e of data_keyword_search_volume[data].details){
          domain_competition_match = e.domain_competition_match
          if(e.search_volume){
            search_volume = e.search_volume
            break;
          }
        }

        keyword_search_volume.push({
          keyword: data_keyword_search_volume[data].keyword,
          language: data_keyword_search_volume[data].language,
          language_name: data_keyword_search_volume[data].language_name,
          locale: data_keyword_search_volume[data].locale,
          locale_name: data_keyword_search_volume[data].locale_name,
          search_engine: data_keyword_search_volume[data].search_engine,
          domain_competition_match: domain_competition_match,
          search_volume: search_volume,
        })
      }
      return keyword_search_volume
    },
    getDataKeywordRanking(post){
      this.loading_report_ranking = true
      this.axios.post('/tracking/dashboard/get-keyword-ranking', post)
      .then(res => {
        //console.log(res.data)
        this.SetThemeChart()
        this.count_api = 0
        this.report_ranking = res.data.report_ranking
        this.InitChartRank()
      })
      .catch(err => {
        if(this.count_api <= 3){
          this.count_api++
          this.initDataKeywordSearchVolume(post)
        }
        if (err && err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => this.loading_report_ranking = false)
    },
    getDataKeywordPerformance(post){
      this.loading_keyword_performance = true
      this.axios.post('/tracking/dashboard/get-keyword-performance', post)
      .then(res => {
        //console.log(res.data)
        this.SetThemeChart()
        this.count_api = 0
        let keyword_performance = res.data.keyword_performance
        let date = res.data.date.reverse()
        this.keyword_performance = this.initKeywordPerformance(keyword_performance,date)
        this.InitChartKeywordPerformance()
        //this.InitChartKeywordPerformanceHistorical()
      })
      .catch(err => {
        if(this.count_api <= 3){
          this.count_api++
          this.getDataKeywordPerformance(post)
        }
        if (err && err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => this.loading_keyword_performance = false)
    },
    getDataKeywordPerformanceHistorical(post){
      this.loading_keyword_performance_historical = true
      this.axios.post('/tracking/dashboard/get-keyword-performance', post)
      .then(res => {
        //console.log(res.data)
        this.SetThemeChart()
        this.count_api = 0
        let keyword_performance = res.data.keyword_performance
        let date = res.data.date
        this.keyword_performance_historical = this.initKeywordPerformance(keyword_performance,date)
        this.InitTableKeywordPerformanceHistorical()
      })
      .catch(err => {
        if(this.count_api <= 3){
          this.count_api++
          this.getDataKeywordPerformanceHistorical(post)
        }
        if (err && err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => this.loading_keyword_performance_historical = false)
    },
    getDataBacklink(post){
      this.loading_backlink = true
      this.axios.post('/tracking/dashboard/get-backlink', post)
      .then(res => {
        //console.log(res.data)
        this.SetThemeChart()
        this.InitChartBacklink(res.data.data)
      })
      .catch(err => {
        //console.log(err.response)
        if(this.count_api <= 3){
          this.count_api++
          this.getDataBacklink(post)
        }
        if (err && err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => this.loading_backlink = false)
    },
    InitChartBacklink(data) {
      //console.log(data)
      //this.chart_backlink.yAxis.title.text = this.$t('quantity')
      let series = []
      let data_backlink = []
      for(let e of data.backlink){
        data_backlink.push({
          x: this.moment(e.date).unix()*1000,
          y: e.total,
        })
      }
      let color_hover_1 = this.$vuetify.theme.dark ? '#FFF' : '#000'
      //let color_hover_2 = this.$vuetify.theme.dark ? '#FFF' : '#CCCCCC'
      let color_hover_3 = '#666666'
      series.push({
        name: this.$t('order'),
        data : data_backlink,
        yAxis: 0,
        events: {
          mouseOver: function() {
                this.chart.yAxis[0].update({
                    lineWidth: 4,
                    title: {
                        style: {
                            color: color_hover_1 // Highlight title
                        }
                    },
                    labels: {
                        style: {
                            color: color_hover_1,
                            fontSize: '14px'
                        }
                    }
                });
                this.chart.yAxis[1].update({
                    lineWidth: 1,
                    title: {
                        style: {
                            color: color_hover_3 // Diminish other title
                        }
                    },
                    labels: {
                        style: {
                            color: color_hover_3,
                            fontSize: '14px'
                        }
                    }
                });
            },
            mouseOut: function() {
                this.chart.yAxis[0].update({
                    lineWidth: 2,
                    title: {
                        style: {
                            color: color_hover_3 // Reset title color
                        }
                    },
                    labels: {
                        style: {
                            color: color_hover_3,
                        }
                    }
                });
                this.chart.yAxis[1].update({
                    lineWidth: 2,
                    title: {
                        style: {
                            color: color_hover_3 // Reset title color
                        }
                    },
                    labels: {
                        style: {
                            color: color_hover_3
                        }
                    }
                });
            }
        }
      });
      
      let data_serpclick = []
      for(let e of data.serpclick){
        data_serpclick.push({
          x: this.moment(e.date).unix()*1000,
          y: e.total,
        })
      }     
     
      series.push({
        name: this.$t('serpclick'),
        data : data_serpclick,
        yAxis: 1,
        events: {
          mouseOver: function() {
                this.chart.yAxis[1].update({
                    lineWidth: 4,
                    title: {
                        style: {
                            color: color_hover_1 // Highlight title
                        }
                    },
                    labels: {
                        style: {
                            color: color_hover_1,
                            fontSize: '14px'
                        }
                    }
                });
                this.chart.yAxis[0].update({
                    lineWidth: 1,
                    title: {
                        style: {
                            color: color_hover_3 // Diminish other title
                        }
                    },
                    labels: {
                        style: {
                            color: color_hover_3,
                            fontSize: '14px'
                        }
                    }
                });
            },
            mouseOut: function() {
                this.chart.yAxis[1].update({
                    lineWidth: 2,
                    title: {
                        style: {
                            color: color_hover_3 // Reset title color
                        }
                    },
                    labels: {
                        style: {
                            color: color_hover_3,
                        }
                    }
                });
                this.chart.yAxis[0].update({
                    lineWidth: 2,
                    title: {
                        style: {
                            color: color_hover_3 // Reset title color
                        }
                    },
                    labels: {
                        style: {
                            color: color_hover_3
                        }
                    }
                });
            }
        }
      });
      //console.log(series)
      // for(let e of backlink.orders){
      //   let array = [];
      //   if(backlink.backlinks[e.uuid]){
      //     for(let v of backlink.backlinks[e.uuid]){
      //       let sub = [];
      //       sub.push(this.moment(v.created_at).unix()*1000);
      //       sub.push(v.count_backlink);
      //       array.push(sub);
      //     }
      //     let keyword = JSON.parse(e.keywords)
      //     let domains = JSON.parse(e.links)
      //     let new_domain = [];
      //     for(let domain of domains){
      //       new_domain.push(this.encodeDecodeURL(domain))
      //     }

      //     series.push({
      //       name: (keyword.length ? keyword.join(",") : '') + ' (' + (new_domain.length ? new_domain.join(",") : '') + ') <span style="color:'+(e.status_serpclick ? '#4CAF50' : '#03A9F4')+'">'+(e.status_serpclick ? this.$t('serpclick') : this.$t('backlink'))+'</span>' ,
      //       data : array
      //     });
      //   }
      // }

      this.has_backlink = false
      if(data.serpclick.length || data.backlink.length){
        this.has_backlink = true
      }
      
      let color = this.$vuetify.theme.dark ? '#FFF' : '#000'
      this.chart_backlink.yAxis = [{
          title: {
              text: this.$t('order'),
              style: {
                color: color // Default color for title
              }  
          },
          labels: {
              align: 'right',
              x: -3,
              style: {
                  color: color_hover_3, // Default color
                  fontSize: '14px' // Default font size
              }
          },
          height: '100%',
          lineWidth: 2,
          resize: {
              enabled: true
          },
          opposite: false // กำหนดให้อยู่ด้านซ้าย
      }, {
          title: {
              text: this.$t('serpclick'),
              style: {
                color: color // Default color for title
              }
          },
          labels: {
              align: 'left',
              x: 3,
              style: {
                  color: color_hover_3, // Default color
                  fontSize: '14px' // Default font size
              }
          },
          //top: '65%',
          height: '100%',
          offset: 0,
          lineWidth: 2,
          opposite: true // กำหนดให้อยู่ด้านขวา
      }]

      this.chart_backlink.tooltip = {
        headerFormat: '<span>{point.x:%d/%m/%Y}</span><br/>', 
        split: false,
        shared: false,  // ตั้งค่านี้เป็น false เพื่อให้ tooltip ไม่แสดงขึ้นพร้อมกันสำหรับทุกเส้น
        crosshairs: true  // เปิดใช้งาน crosshairs เพื่อช่วยในการติดตามเส้นบนกราฟ
      }
      this.chart_backlink.series = series
    },
    compareRanking( a, b ) {
			if ( a.ranking < b.ranking ){
				return -1;
			}
			if ( a.ranking > b.ranking ){
				return 1;
			}
			return 0;
		},
    CreateTooltipKeyword(data,name){
        //console.log(data)
        //console.log(name)
        let that = this
        let newdata = JSON.parse(JSON.stringify(data))
        let filter_array = newdata.filter(function(e){
          if(name == that.$t('top_3')){
            return  e.ranking >= 1 && e.ranking <= 3
          }
          else if(name == that.$t('page_1')){
            return e.ranking >= 1 && e.ranking <= 10
          }
          else if(name == that.$t('page_2')){
            return e.ranking > 10 && e.ranking <= 20
          }
          else if(name == that.$t('page_3')){
            return e.ranking > 20 && e.ranking <= 30
          }
          else if(name == that.$t('top_100')){
            return e.ranking > 30 && e.ranking <= 100
          }
          else{
            return e.ranking > 100
          }
        })
        //console.log(filter_array)
        let html = ''
        for(let e of filter_array){
          html += e.keyword+' ('+this.encodeDecodeURL(e.domain)+'):  '+e.ranking+'<br/>'
        }
        return html
    },  
  },
  computed: {
    
  }
}
</script>
<style>
.background-color-black{
  background-color: black;
}
.v-dialog--fullscreen{
  overflow: hidden;
}
.background_green{
  border-radius: 100%;
  background-color: rgb(26 188 156 / 25%)!important;
  color: inherit!important;
}
.background_red{
  border-radius: 100%;
  background-color: rgb(255 78 101 / 25%)!important;
  color: inherit!important;
}
.td_monthly_search{
  text-align: center;
}
.tag-input{
  margin-top: 0!important;
  padding-top: 0!important;
}
.tag-input .v-input__slot{
  margin-bottom: 0!important;
  padding-bottom: 0!important;
}
.tag-input .v-text-field__details{
  display: none!important;
}
.radio-group-container{
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
}
.radio_group_loading .v-skeleton-loader__button{
  width: 300px;
}
.border-radius-circle{
  border-radius: 100px!important;
}
.input-rival{
  height: 55px;
}
.input-token{
  width: 100%;
  height: 55px;
}
@media (max-width: 600px) {
  .input-search{
    width: 100%;
  }
}

.viewContainer .v-data-table{
  border-radius: 0!important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.custom-text-field{
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;
}
.chip_domain .v-chip__content{    
  padding: 5px;  
  height: 50px;
  max-width: 250px;
  overflow-wrap: break-word;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
}

/* .hide-icon + .v-icon {
    display: none;
} */
</style>

