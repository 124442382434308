<template>
  <div class="viewContainer" >
    <v-container
      :class="['pa-4', 'pa-sm-8', $store.state.app.classExpandContainer]"
    >
      <v-btn 
        v-if="$route.query && $route.query.enterprise_uuid"
        :to="{ name: 'admin.users' }"
        small
        rounded
        depressed 
        class="no-caps mb-4"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-card class="content-tabs-wrapper pa-4 pa-sm-8" elevation="0">
        
        <menu_keyword/>
        <panel_create
        :title="$t('keyword')"
        :msg="$t('msg_title_create_keyword')"
        :create_item="$t('create_keyword')"
        :redirect="'tracking-add-keyword'"
        >
        </panel_create>
        <data-table
          model="App\Models\Keyword"
          :enterprise="enterprise"
          :user_uuid="enterprise_uuid"
        >
          <template v-slot:empty-head>
            <v-icon size="72">mdi-folder-multiple</v-icon>
          </template>
          <template v-slot:empty-text>
          </template>
        </data-table>
      </v-card>
    </v-container>
  </div>
</template>


<script>
// import { copyStringToClipboard } from '@/utils/helpers';
// import country from '@/json/country.json'

export default {
  data: () => ({
    locale: 'en',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: false,
    enterprise: false,
    enterprise_uuid: null,
  }),
  created () {
    // Set locale
    //console.log(this.country)
    this.locale = (this.$auth.check()) ? this.$auth.user().locale: Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
    this.moment.locale(this.locale.substr(0,2))

    if(!this.$auth.user().page_keyword){
      this.$router.push({
        name: 'settings.profile',
      })
    }

    if(this.$route.query && this.$route.query.enterprise_uuid){
      this.enterprise = true
      this.enterprise_uuid = this.$route.query.enterprise_uuid
      this.$root.$refs.master.getCredit()
    }
  },
  methods: {
    //copyStringToClipboard,
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
  },
  computed: {
    qrWidth: function () {
      let w = parseInt(window.innerWidth) - 72
      return (w > 443) ? 443 : w
    }
  }
}
</script>
<style>
.background-color-black{
  background-color: black;
}
.v-dialog--fullscreen{
  overflow: hidden;
}
</style>