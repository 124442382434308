<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <v-btn 
        :to="{ 
            name: 'tracking-keyword',
            query: $route.query
          }"
          small
          rounded
          depressed 
          class="no-caps mb-4"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t('back') }}
      </v-btn>
      <!-- <v-btn @click="loading_main ? loading_main=false : loading_main=true">test</v-btn> -->
      <ValidationObserver ref="validateKeyword" v-slot="{ invalid }">
        <v-form 
          ref="formKeyword" 
          @submit.prevent="ActiveKeyword"
        >
          <v-card rounded>
            <v-card-title class="headline">{{ $t('active_bulk') }}</v-card-title>
            <v-card-text>
              <v-tabs 
                v-model="formKeyword.tab"
                :color="($store.state.app.dark) ? 'white' : 'black'"
                class="content-tabs mb-3"
                background-color="transparent"
                fixed-tabs
                show-arrows
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab class="no-caps" :href="'#keyword'" :ripple="false">
                  {{ $t('keyword') }}
                </v-tab>
                <v-tab class="no-caps" :href="'#url'" :ripple="false">
                  {{ $t('url') }}
                </v-tab>
                <v-tab class="no-caps" :href="'#keyword_url'" :ripple="false">
                  {{ $t('keyword') }} {{  $t('url') }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="formKeyword.tab" :touchless="false">
                <v-tab-item v-if="formKeyword.tab == 'keyword'" :transition="false" :reverse-transition="false" :value="'keyword'">

                  <div v-if="formKeyword.search_engine == 'youtube'">
                    <x-text-area
                      v-if="!loading_main"
                      v-model="formKeyword.keyword_only_multiple"
                      @keyup="setDataKeyword"
                      :label="$t('keyword')"
                      filled
                      rounded
                      persistent-placeholder
                      persistent-hint
                      label-placement="on-top"
                      rules="required"
                      class="custom-text-field mt-4 text-area-wrap"
                      @keydown.tab.prevent="handleTab"
                      :error="formKeyword.error_keyword_multiple"
                      :placeholder="$t('keyword') + '1\n'+$t('keyword') + '2\n'+$t('keyword') + '3'"
                    ></x-text-area>
                  </div>
                  <div v-else>
                    <x-text-area
                      v-if="!loading_main"
                      v-model="formKeyword.keyword_only_multiple"
                      @keyup="setDataKeyword"
                      :label="$t('keyword')"
                      filled
                      rounded
                      persistent-placeholder
                      persistent-hint
                      label-placement="on-top"
                      rules="required"
                      class="custom-text-field mt-4 text-area-wrap"
                      @keydown.tab.prevent="handleTab"
                      :error="formKeyword.error_keyword_multiple"
                      :placeholder="$t('keyword') + '1\n'+$t('keyword') + '2\n'+$t('keyword') + '3'"
                    ></x-text-area>
                  </div>

                </v-tab-item>
                <v-tab-item v-if="formKeyword.tab == 'url'" :transition="false" :reverse-transition="false" :value="'url'">

                  <div v-if="formKeyword.search_engine == 'youtube'">
                    <x-text-area
                      v-if="!loading_main"
                      v-model="formKeyword.url_only_multiple"
                      @keyup="setDataKeyword"
                      :label="$t('url')"
                      filled
                      rounded
                      persistent-placeholder
                      persistent-hint
                      label-placement="on-top"
                      rules="required"
                      class="custom-text-field mt-4 text-area-wrap"
                      @keydown.tab.prevent="handleTab"
                      :error="formKeyword.error_keyword_multiple"
                      :placeholder="$t('url') + '1\n'+$t('url') + '2\n'+$t('url') + '3'"
                    ></x-text-area>
                  </div>
                  <div v-else>
                    <x-text-area
                      v-if="!loading_main"
                      v-model="formKeyword.url_only_multiple"
                      @keyup="setDataKeyword"
                      :label="$t('url')"
                      filled
                      rounded
                      persistent-placeholder
                      persistent-hint
                      label-placement="on-top"
                      rules="required"
                      class="custom-text-field mt-4 text-area-wrap"
                      @keydown.tab.prevent="handleTab"
                      :error="formKeyword.error_keyword_multiple"
                      :placeholder="$t('url') + '1\n'+$t('url') + '2\n'+$t('url') + '3'"
                    ></x-text-area>
                  </div>

                </v-tab-item>
                <v-tab-item v-if="formKeyword.tab == 'keyword_url'" :transition="false" :reverse-transition="false" :value="'keyword_url'">
                  <div v-if="formKeyword.search_engine == 'youtube'">
                    <x-text-area
                      v-if="!loading_main"
                      v-model="formKeyword.keyword_multiple"
                      @keyup="setDataKeyword"
                      :label="$t('keyword') + ' https://www.youtube.com/watch?v=url'"
                      filled
                      rounded
                      persistent-placeholder
                      persistent-hint
                      label-placement="on-top"
                      rules="required"
                      class="custom-text-field mt-4 text-area-wrap"
                      @keydown.tab.prevent="handleTab"
                      :error="formKeyword.error_keyword_multiple"
                      :placeholder="$t('keyword') + '1|https://www.youtube.com/watch?v=url1\n' + $t('keyword') + '2,https://www.youtube.com/watch?v=url2\n' + $t('keyword') + '3  https://www.youtube.com/watch?v=url3'"
                    ></x-text-area>
                  </div>
                  <div v-else>
                    <x-text-area
                      v-if="!loading_main"
                      v-model="formKeyword.keyword_multiple"
                      @keyup="setDataKeyword"
                      :label="$t('keyword') + ' ' + $t('url')"
                      filled
                      rounded
                      persistent-placeholder
                      persistent-hint
                      label-placement="on-top"
                      rules="required"
                      class="custom-text-field mt-4 text-area-wrap"
                      @keydown.tab.prevent="handleTab"
                      :error="formKeyword.error_keyword_multiple"
                      :placeholder="$t('keyword') + '1|' + $t('url') + '1\n' + $t('keyword') + '2,' + $t('url') + '2\n' + $t('keyword') + '3  ' + $t('url') + '3'"
                    ></x-text-area>
                  </div>
                </v-tab-item>
              </v-tabs-items>

              <loading_input v-if="loading_main"/>

              <loading_input v-if="loading_main"/>

              <loading_input v-if="loading_main"/>

              <x-select
                v-if="!loading_main"
                v-model="formKeyword.search_engine"
                :items="formKeyword.search_engines"
                item-text="name"
                item-value="value"
                :label="$t('search_engine')"
                @change="selectSearchEngine()"
                filled
              ></x-select>

              <div class="" v-if="formKeyword.locale_item && formKeyword.has_locale">   
                <div class="text-h6">{{$t('locale')}} : {{ formKeyword.locale_item.label }}</div>
                <div class="text-body-1">{{$t('type')}} : {{ $t(formKeyword.locale_item.type) }}</div>   
              </div>
              <!-- <x-autocomplete
                v-if="has_locale"
                v-model="locale"
                :items="locations"
                item-text="label"
                item-value="value"
                :label="$t('locale')"
                ref="locale"
                filled
                rules="required"
                clearable
                @change="selectLocal()"
              ></x-autocomplete> -->

              <loading_input v-if="loading_main"/>
              <x-autocomplete
                v-if="formKeyword.has_locale && !loading_main"
                v-model="formKeyword.locale"
                :loading="formKeyword.loading_locale"
                :items="formKeyword.array_locale"
                item-text="label"
                item-value="value"
                :search-input.sync="search_locale"
                :label="$t('locale')"
                ref="locale"
                filled
                clearable
                @change="selectLocal()"
                rules="required"
              >
              </x-autocomplete>

              <div class="" v-if="formKeyword.language_item && formKeyword.has_language">   
                <div class="text-h6">{{$t('language')}} : {{ formKeyword.language_item.label }}</div>
              </div>

              <!-- <x-autocomplete
                v-if="has_language"
                v-model="language"
                :items="languages"
                item-text="label"
                item-value="value"
                :label="$t('language')"
                ref="language"
                filled
                rules="required"
                clearable
                @change="selectLanguage()"
              ></x-autocomplete> -->

              <loading_input v-if="loading_main"/>
              <x-autocomplete
                v-if="formKeyword.has_language && !loading_main"
                v-model="formKeyword.language"
                :loading="formKeyword.loading_language"
                :items="formKeyword.array_language"
                item-text="label"
                item-value="value"
                :search-input.sync="search_language"
                :label="$t('language')"
                ref="language"
                filled
                clearable
                @change="selectLanguage()"
                rules="required"
              >
              </x-autocomplete>

              <loading_input v-if="loading_main"/>
              <x-select
                v-if="formKeyword.has_device && !loading_main"
                v-model="formKeyword.device"
                :items="formKeyword.devices"
                item-text="name"
                item-value="value"
                :label="$t('device')"
                @change="selectDevice()"
                rules="required"
                filled
              ></x-select>

              <loading_input v-if="loading_main"/>
              <x-select
                v-if="formKeyword.has_os && !loading_main"
                v-model="formKeyword.os"
                :items="formKeyword.item_os"
                item-text="name"
                item-value="value"
                :label="$t('os')"
                rules="required"
                filled
                @change="setDataKeyword"
              ></x-select>

              <!-- <x-select 
                v-if="has_depth"
                v-model="depth"
                :items="item_depth"
                item-text="name"
                item-value="value"
                :label="$t('depth')"
                rules="required"
                filled
              ></x-select> -->
              <loading_input v-if="loading_main"/>
              <x-text-field
                v-if="formKeyword.has_search_engine_parameters && !loading_main"
                v-model="formKeyword.search_engine_parameters"
                :label="$t('search_engine_parameters')"
                placeholder="example: &tbs=qdr:h"
                filled
                @keyup="setDataKeyword"
              ></x-text-field>

              <!-- <x-select
                v-model="date_update_ranking_id"
                :items="item_date"
                :item-text="SelectTextDate"
                item-value="id"
                :label="$t('date_update_ranking')"
                filled
              ></x-select> -->

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-skeleton-loader
                v-if="loading_main" 
                class="mx-auto"
                type="button"
              ></v-skeleton-loader>
              <v-btn 
                v-if="!loading_main" 
                type="submit" 
                color="primary" 
                depressed 
                class="no-caps"
                :loading="loading" :disabled="loading || invalid"
                >{{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>


  export default {
    data: () => ({
      loading_main: true,
      resolve: null,
      reject: null,
      loading: false,
      search_locale: null,
      search_language: null,
      formKeyword: {
        tab: 'keyword',
        keyword: null,
        website: null,
        keyword_multiple: null,  
        locations: [],
        languages: [],
        _searchTimer: null,
        array_locale: [],
        loading_locale: false,
        locale: null,
        locale_item: null,
        search_locale: null,
        array_language: [],
        loading_language: false,
        language: null,
        language_item: null,
        search_language: null,
        search_engines: [],
        search_engine: 'google',
        item_date: [],
        search_engine_parameters: null,
        devices: [],
        device: null,
        item_os: [],
        os: null,
        item_depth: [],
        depth: null,
        has_locale: false,
        has_language: false,
        has_device: false,
        has_os: false,
        has_depth: false,
        has_search_engine_parameters: false,
        date_update_ranking_id: 6,
        my_package_keyword: []
      },
      
    }),
    created () {
      // Set locale
      if(!this.$auth.user().page_keyword){
        this.$router.push({
          name: 'settings.profile',
        })
      }
      this.open()
    },
    watch: {
      search_locale(val) {
        // console.log(val)
        // console.log(this.formKeyword.locale)
        if(!this.formKeyword.locale){
          if (!val) {
            return
          }
          this.clearEntries('locale')
          this.formKeyword.search_locale = val
          this.formKeyword.loading_locale = true
          this.fetchEntriesDebounced('locale')
        }
      },
      search_language(val) {
        if(!this.formKeyword.language){
          if (!val) {
            return
          }
          this.clearEntries('language')
          this.formKeyword.search_language = val
          this.formKeyword.loading_language = true
          this.fetchEntriesDebounced('language')
        }
      },
    },
    methods: {
      handleTab(event) {
        const TAB = '\t';  // เว้นวรรค 4 ช่อง คุณสามารถปรับเป็นตามที่ต้องการ
        const start = event.target.selectionStart;
        const end = event.target.selectionEnd;

        // ใส่ TAB ลงในตำแหน่งที่เลือกและอัปเดตตำแหน่ง cursor
        this.formKeyword.keyword_multiple = this.formKeyword.keyword_multiple.substring(0, start) + TAB + this.formKeyword.keyword_multiple.substring(end);
        this.$nextTick(() => {
          event.target.selectionStart = event.target.selectionEnd = start + TAB.length;
        });
      },
      clearEntries(type) {
        this.formKeyword['array_'+type] = []
      },
      fetchEntriesDebounced(type) {
        clearTimeout(this.formKeyword._searchTimer)
        this.formKeyword._searchTimer = setTimeout(() => {
          this.query(this.formKeyword['search_'+ type],type)
        }, 500) /* 500ms throttle */
      },
      async query(v,type) {
        if(v){
          //console.log(v)
          await this.axios.post('/tracking/keyword/get-'+type, {
            search: v,
            type: this.formKeyword.search_engine,
          })
          .then((response) => {
            this.formKeyword['array_'+type] = response.data.data
          })
          .catch((error) => {
            this.$root.$snackbar(this.$t('not_found'))
          })
          .finally(() => {
            this.formKeyword['loading_'+type] = false
          })

        }
      },
      selectSearchEngine(){

        if(this.formKeyword.search_engine == 'google'){
          this.formKeyword.has_locale = true
          this.formKeyword.has_language = true
          this.formKeyword.has_device = true
          this.formKeyword.has_os = true
          this.formKeyword.has_search_engine_parameters = true
        }
        else if(this.formKeyword.search_engine == 'bing'){
          this.formKeyword.has_locale = true
          this.formKeyword.has_language = true
          this.formKeyword.has_device = true
          this.formKeyword.has_os = true
          this.formKeyword.has_search_engine_parameters = true
        }
        else if(this.formKeyword.search_engine == 'yahoo'){
          this.formKeyword.has_locale = true
          this.formKeyword.has_language = true
          this.formKeyword.has_device = true
          this.formKeyword.has_os = true
          this.formKeyword.has_search_engine_parameters = true
        }
        else if(this.formKeyword.search_engine == 'youtube'){
          this.formKeyword.has_locale = true
          this.formKeyword.has_language = true
          this.formKeyword.has_device = true
          this.formKeyword.has_os = true
          this.formKeyword.has_search_engine_parameters = false
        }
        else if(this.formKeyword.search_engine == 'baidu'){
          this.formKeyword.has_locale = true
          this.formKeyword.has_language = true
          this.formKeyword.has_device = true
          this.formKeyword.has_os = true
          this.formKeyword.has_search_engine_parameters = false
        }
        else if(this.formKeyword.search_engine == 'naver'){
          this.formKeyword.has_locale = false
          this.formKeyword.has_language = false
          this.formKeyword.has_device = true
          this.formKeyword.has_os = true
          this.formKeyword.has_search_engine_parameters = true
        }
        else if(this.formKeyword.search_engine == 'seznam'){
          this.formKeyword.has_locale = true
          this.formKeyword.has_language = true
          this.formKeyword.has_device = true
          this.formKeyword.has_os = true
          this.formKeyword.has_search_engine_parameters = true
        }

        if(this.formKeyword.search_engine != 'naver'){
          this.axios.post('/tracking/keyword/get-data-create', {
            type: this.formKeyword.search_engine,
          })
          .then(res => {
            if (res.data.status === 'success') {
              this.formKeyword.array_locale = res.data.locations
              this.formKeyword.array_language = res.data.languages
              this.formKeyword.locale = null
              this.formKeyword.language = null
              this.formKeyword.locale_item = null
              this.formKeyword.language_item = null
              this.setDataKeyword()
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
        }
        else{

          this.formKeyword.locale = null
          this.formKeyword.language = null
          this.formKeyword.locale_item = null
          this.formKeyword.language_item = null
          this.setDataKeyword()
        }
      },
      selectDevice(){
        if(this.formKeyword.device == 'desktop'){
          this.formKeyword.item_os = [
            {
              value: 'windows',
              name: 'Windows'
            },
            {
              value: 'macos',
              name: 'Mac OS'
            }
          ]
          this.formKeyword.os = 'windows'
        }
        else if(this.formKeyword.device == 'mobile'){
          this.formKeyword.item_os = [
            {
              value: 'android',
              name: 'Android'
            },
            {
              value: 'ios',
              name: 'iOS'
            }
          ]
          this.formKeyword.os = 'android'
        }
        this.setDataKeyword()
      },
      selectLocal(){
        let item = this.formKeyword.array_locale.find(e => e.value == this.formKeyword.locale)
        if(item){
          this.formKeyword.locale_item = item
        }
        else{
          this.formKeyword.locale_item = null
        }
        this.setDataKeyword()
      },
      selectLanguage(){
        let item = this.formKeyword.array_language.find(e => e.value == this.formKeyword.language)
        if(item){
          this.formKeyword.language_item = item
        }
        else{
          this.formKeyword.language_item = null
        }
        this.setDataKeyword()
      },
      open() {
        if(this.$store.state && this.$store.state && this.$store.state.app.data_keyword && this.$store.state.app.data_keyword.formKeyword){
          this.formKeyword = this.$store.state.app.data_keyword.formKeyword
          this.loading_main = false
        }
        else{
          this.loading_main = true
          this.axios.post('/tracking/keyword/get-data-create', {
            type: 'google',
          })
          .then(res => {
            if (res.data.status === 'success') {
              this.formKeyword.search_engines = res.data.search_engines
              this.formKeyword.array_locale = res.data.locations
              this.formKeyword.array_language = res.data.languages
              this.setData()
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading_main = false)
        }
      },
      setDataKeyword(){
        let data_keyword = this.$store.state.app.data_keyword
        data_keyword['formKeyword'] = this.formKeyword
        this.$store.dispatch('setDataKeyword', data_keyword)
      },
      setData() {
        if(this.$refs.validateKeyword){
          this.$refs.validateKeyword.reset();
        }
        this.formKeyword.keyword = null
        this.formKeyword.website = null
        this.formKeyword.keyword_multiple = null
        this.formKeyword.keyword_only_multiple = null
        this.formKeyword.url_only_multiple = null
        this.formKeyword.locale = null
        this.formKeyword.language = null
        this.formKeyword.locale_item = null
        this.formKeyword.language_item = null
        this.formKeyword.search_engine = 'google'
        this.formKeyword.devices = [
        {
            value: 'desktop',
            name: this.$t('desktop')
          },
          {
            value: 'mobile',
            name: this.$t('mobile')
          }
        ]
        this.formKeyword.item_os = [
          {
            value: 'windows',
            name: 'Windows'
          },
          {
            value: 'macos',
            name: 'Mac OS'
          }
        ]
        this.formKeyword.device = 'desktop',
        this.formKeyword.os = 'windows'
        this.formKeyword.item_depth = []
        for(let i=1;i<=70;i++ ){
          this.formKeyword.item_depth.push({
            value: i*10,
            name: i*10
          })
        }
        // this.formKeyword.depth = 100
        this.formKeyword.has_locale = true
        this.formKeyword.has_language = true
        this.formKeyword.has_device = true
        this.formKeyword.has_os = true
        // this.formKeyword.has_depth = true
        this.formKeyword.has_search_engine_parameters = true
        this.formKeyword.search_engine_parameters = null
        this.setDataKeyword()
      },
      SelectTextDate(e){
        //console.log(e)
        return e.date+' '+this.$t(e.format)
      },
      findDuplicates(arr) {
        const unique = new Map();
        const duplicates = [];

        arr.forEach(item => {
          const key = `${item.keyword}-${item.url}`;
          if (unique.has(key)) {
            // เมื่อพบว่ามีซ้ำ, ใส่เข้าไปใน array ของ duplicates (ถ้ายังไม่มีอยู่ในนั้น)
            if (!duplicates.some(duplicate => duplicate.keyword === item.keyword && duplicate.url === item.url)) {
              duplicates.push(item);
            }
          } else {
            unique.set(key, item);
          }
        });

        return duplicates;
      },
      ActiveKeyword() {
        let array_keyword_url = []
        let array_keyword = []
        //console.log(this.formKeyword.tab)
        if(this.formKeyword.tab == 'keyword'){
          //console.log(this.formKeyword.keyword_only_multiple)
          array_keyword = this.formKeyword.keyword_only_multiple.split('\n')
          //console.log(array_keyword)
        }
        else if(this.formKeyword.tab == 'url'){
          array_keyword = this.formKeyword.url_only_multiple.split('\n')
        }
        else{
          array_keyword = this.formKeyword.keyword_multiple.split('\n')
        }
        
        //console.log(array_keyword)
        array_keyword_url = []
        if(!array_keyword.length){
          this.$root.$snackbar(this.$t('invalid_value'))
          return false
        }

        this.formKeyword.error_keyword_multiple = false
        this.formKeyword.error_url_youtube = false
        this.formKeyword.error_channel_youtube = false
        this.formKeyword.error_website = false

        //console.log(this.formKeyword.tab)
        for(let keyword of array_keyword){
          if(!keyword){
            continue;
          }
          if(this.formKeyword.tab == 'keyword'){
            array_keyword_url.push({
              keyword: keyword,
            })
          }
          if(this.formKeyword.tab == 'url'){
            if(this.formKeyword.search_engine == 'youtube'){
              const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,})$/gi;
              if(!pattern.test(keyword)){
                this.$root.$snackbar(this.$t('invalid_website'))
                this.formKeyword.error_keyword_multiple = true
                return false
              }
              array_keyword_url.push({
                url: keyword.split("?")[0],
              })
            }
            else{
              const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,})$/gi;
              if(!pattern.test(keyword)){
                this.$root.$snackbar(this.$t('invalid_website'))
                this.formKeyword.error_keyword_multiple = true
                return false
              }
              array_keyword_url.push({
                url: keyword,
              })
            }
          }
          else{
            if(this.formKeyword.tab == 'keyword_url'){
              if(this.formKeyword.search_engine == 'youtube'){
                let array = keyword.split('|')
                if(array.length != 2){
                  array = keyword.split('\t')
                  if(array.length != 2){
                    array = keyword.split(',')
                    if(array.length != 2){
                      this.$root.$snackbar(this.$t('invalid_value'))
                      this.formKeyword.error_keyword_multiple = true
                      return false
                    }
                  }
                }
                //const youtubePattern = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=[a-zA-Z0-9_-]+$/;
                const youtubePattern = /^(https?:\/\/)?(www\.)?youtube\.com\/(watch\?v=|shorts\/)[a-zA-Z0-9_-]+/;
                if(!youtubePattern.test(array[1])){
                  this.$root.$snackbar(this.$t('invalid_url_youtube'))
                  this.formKeyword.error_keyword_multiple = true
                  return false
                }

                array_keyword_url.push({
                  keyword: array[0],
                  url_youtube: array[1],
                })
              }
              else{
                let array = keyword.split('|')
                if(array.length != 2){
                  array = keyword.split('\t')
                  if(array.length != 2){
                    array = keyword.split(',')
                    if(array.length != 2){
                      this.$root.$snackbar(this.$t('invalid_value'))
                      this.formKeyword.error_keyword_multiple = true
                      return false
                    }
                  }
                }
                const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,})$/gi;
                if(!pattern.test(array[1])){
                  this.$root.$snackbar(this.$t('invalid_website'))
                  this.formKeyword.error_keyword_multiple = true
                  return false
                }
                array_keyword_url.push({
                  keyword: array[0],
                  url: array[1].split("?")[0],
                })
              }
            }
            
          }

          
        }

        if(this.formKeyword.search_engine != 'naver' && (!this.formKeyword.locale || !this.formKeyword.language)){
          this.$root.$snackbar(this.$t('invalid_value'))
          return false
        }

        this.loading = true
        let serp_provider_id = this.formKeyword.search_engines[0].uuid
        let serp_provider = this.formKeyword.search_engines.find(e => e.value == this.formKeyword.search_engine)
        if(serp_provider){
          serp_provider_id = serp_provider.uuid
        }
        let data_post = {
          search_engine: this.formKeyword.search_engine,
          serp_provider_id: serp_provider_id,
          locale: this.formKeyword.locale,
          locale_name: (this.formKeyword.locale_item ? this.formKeyword.locale_item.label : ''),
          language: this.formKeyword.language,
          language_name: (this.formKeyword.language_item ? this.formKeyword.language_item.label : ''),
          device: this.formKeyword.device,
          os: this.formKeyword.os,
          search_engine_parameters: this.formKeyword.search_engine_parameters ? this.formKeyword.search_engine_parameters : ''
        }

        
        data_post['array_keyword_url'] = JSON.stringify(array_keyword_url)
        
        if(this.$route.query && this.$route.query.enterprise_uuid){
          data_post['enterprise_uuid'] = this.$route.query.enterprise_uuid
        }
        this.axios.post('/tracking/keyword/get-bulk-uuid', data_post)
        .then(res => {
          if (res.data.status === 'success') {
            if(res.data.keyword.length){
              //console.log(res.data)
              let serp_provider_id = res.data.keyword[0].serp_provider_id
              let array_uuid = []
              for(let e of res.data.keyword){
                array_uuid.push(e.uuid)
              }
              this.getPackage(array_uuid,serp_provider_id)
            }
            else{
              this.$root.$snackbar(this.$t('not_found'))
            }
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
          
      },
      getPackage(array_uuid,serp_provider_id){
        //console.log(serp_provider_id)
        this.loading = true
        this.axios.post('/package/keyword/get-date',{
          array_uuid: JSON.stringify(array_uuid),
          serp_provider_id: serp_provider_id,
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          type: 'multiple'
        })
        .then(res => {
          if (res.data.status === 'success') { 
            if(res.data.package_keyword && res.data.package_keyword.length){
              let json_date = []
              for(let item of res.data.package_keyword){ 
                json_date.push({
                  id: item.uuid,
                  date: item.frequency_date,
                  format: item.frequency_date_format,
                  limit_active: item.limit_active_keyword,
                  count_acive: item.count_acive_package,
                  package_date_expired: item.package_date_expired,
                  depth: item.depth,
                })
              }
              this.$root.$select_date_active_keyword(json_date,array_uuid.length)
              .then((confirm) => {
                if (confirm.confirm) {
                  //console.log(confirm.data)
                  //console.log(array_uuid.length)
                  // console.log(confirm.data.count_acive)
                  if(array_uuid.length <= (confirm.data.limit_active-confirm.data.count_acive)){
                    let package_uuid = confirm.data.id
                    let reason = false
                    this.$root.$confirm(this.$t('confirm'), this.$t('confirm_active_seo'),{
                      color: 'primary',
                      width: 360,
                      zIndex: 200
                    },reason)
                    .then((confirm) => {
                      if (confirm.confirm) {
                        this.loading = true
                        this.axios.post('/tracking/keyword/active-all', {
                          array_uuid: JSON.stringify(array_uuid),
                          package_uuid: package_uuid,
                          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
                        })
                        .then(res => {
                          if (res.data.status === 'success') {
                              let data_keyword = this.$store.state.app.data_keyword
                              data_keyword['formKeyword'] = null
                              this.$store.dispatch('setDataKeyword', data_keyword)
                              this.$root.$snackbar(this.$t('item_saved'))
                              this.$router.push({
                                name: 'tracking-keyword', 
                                query: this.$route.query
                              })
                          }
                        })
                        .catch(err => {
                          this.loading = false
                          if (err.response.data.status === 'error') {
                            this.$root.$snackbar(err.response.data.msg)
                          }
                        })
                        .finally(() => this.loading = false)
                      }
                    })
                  }
                  else{
                    this.$root.$snackbar(this.$t('active_limit'))
                  }
                  
                }
              })
            }
            else{
              this.$root.$snackbar(this.$t('alert_buy_package'))
              this.$router.push({
                name: 'tracking-package', 
                query: this.$route.query, 
              })
            }
            
          }
        })
        .catch(err => {
          this.active_all_loading = false
          if (err.response.data.status === 'error') {
            if(err.response.data.type == 'buy_package'){
              this.$root.$snackbar(this.$t('alert_buy_package'))
              this.$router.push({
                name: 'tracking-package-keyword-add', 
                query: this.$route.query, 
                params: { 
                  serp_provider_uuid: err.response.data.serp_provider_uuid,
                }
              })
            }
            else{
              this.$root.$snackbar(err.response.data.msg)
            }
          }
        })
        .finally(() => this.active_all_loading = false)
      }
    }
  }
</script>
<style>
.custom-text-field{
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;
}
</style>