<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <v-btn 
        :to="{ 
          name: 'tracking-package',
          query: $route.query,
          params: {
            tab: 'package_boost_keyword'
          }
          }"
          small
          rounded
          depressed 
          class="no-caps mb-4"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t('back') }}
      </v-btn>
      <ValidationObserver ref="validateBuyPackage" v-slot="{ invalid }">
        <v-form 
          ref="formPackage" 
          @submit.prevent="SubmitBuyPackage"
        >
          <v-card rounded>
            <v-card-title class="pt-5 pl-5 pr-5 pb-2 ma-0">{{ $t('buy_package') }}</v-card-title>
            <v-card-text class="pa-5 ma-0">

              <loading_input v-if="loading_main"/>
              <x-select
                v-if="!loading_main"
                v-model="formPackage.package_search_engine"
                :items="formPackage.item_package_search_engine"
                item-text="providers_name"
                item-value="uuid"
                :label="$t('search_engine')"
                @change="selectPackageSearchEngine()"
                filled
              ></x-select>

              <loading_input v-if="loading_main"/>

              <x-select
                v-if="!loading_main"
                v-model="formPackage.package_top_ranking"
                :items="formPackage.item_package_top_ranking"
                item-text="top_ranking"
                item-value="uuid"
                :label="$t('top_ranking')"
                @change="selectTopRanking()"
                filled
              ></x-select>   

              <loading_input v-if="loading_main"/>
              <x-select
                v-if="!loading_main"
                v-model="formPackage.package_date"
                :items="formPackage.item_package_date"
                :item-text="SelectTextDate"
                item-value="uuid"
                :label="$t('package_date')"
                @change="selectPackageDate()"
                filled
              ></x-select>

              <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          {{ $t('select') }}
                        </th>
                        <th class="text-center">
                          {{ $t('limit_active_keyword') }}
                        </th>
                        <th class="text-center">
                          {{ $t('price') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in formPackage.item_package_limit"
                        :key="item.uuid"
                      >
                        <td class="text-center">
                          <v-radio-group v-model="formPackage.selected_limit_active" @change="setDataPackage">
                            <v-radio :value="item.uuid"/>
                          </v-radio-group>
                        </td>
                        <td class="text-center">{{ item.limit_boost_keyword }}</td>
                        
                        <td class="text-left">
                          <amount_currency :amount="parseFloat(item.price)" top></amount_currency> (<span class="green--text">-{{ item.percent_discount }}</span>%)
                        </td>
                      
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                      <tr v-if="loading_main_price">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              
            </v-card-text>
            <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
              <v-spacer></v-spacer>
              <v-btn 
                type="submit" 
                color="primary" 
                depressed 
                class="no-caps"
                :loading="loading" :disabled="loading || invalid"
                >{{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  
  export default {
    data: () => ({
      formPackage: {
        item_package_date: [],
        package_date: null,
        item_package_limit: [],
        selected_limit_active: null,
        package_search_engine: null,
        item_package_search_engine: [],
        package_top_ranking: null,
        item_package_top_ranking: [],
      },
      loading: false,
      loading_main: true,
      loading_main_price: true,
    }),
    created () {
      // Set locale
      //console.log(this.country)
      this.locale = (this.$auth.check()) ? this.$auth.user().locale: Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
      this.moment.locale(this.locale.substr(0,2))
      if(this.$auth.user().status_enterprise || !this.$auth.user().page_package){
        this.$router.push({
          name: 'settings.profile',
        })
      }
      
      this.open()
    },
    methods: {
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      SelectTextDate(e){
        //console.log(e)
        return e.date + ' ' + this.$t(e.format)
      }, 
      open() { 
        if(this.$store.state && this.$store.state && this.$store.state.app.data_package_boost_keyword && this.$store.state.app.data_package_boost_keyword.formPackage){
          this.formPackage = this.$store.state.app.data_package_boost_keyword.formPackage
          this.getTablePrice()
          this.loading_main = false
        }
        else{
          this.setData()
        }
        
      },
      setDataPackage(){
        let data_package_boost_keyword = this.$store.state.app.data_package_boost_keyword
        data_package_boost_keyword['formPackage'] = this.formPackage
        this.$store.dispatch('setDataPackageBoostKeyword', data_package_boost_keyword)
      },
      setData() {
        //console.log(data)
        this.loading_main = true
        this.formPackage.item_package_limit = []
        this.axios.get('/package/boost-keyword/get-all')
        .then(res => {
          if (res.data.status === 'success') {
            this.formPackage.item_package_date = res.data.package_keyword.package_date
            this.formPackage.item_package_search_engine = res.data.package_keyword.serp_provider
            this.formPackage.item_package_top_ranking = res.data.package_keyword.serp_top_ranking_provider
            this.formPackage.package_date = this.formPackage.item_package_date[0].uuid
            if(this.$route.params && this.$route.params.serp_provider_uuid){
              let item = this.formPackage.item_package_search_engine.find(e => e.uuid == this.formPackage.$route.params.serp_provider_uuid)
              this.formPackage.package_search_engine = item ? item.uuid : this.formPackage.item_package_search_engine[0].uuid
            }
            else{
              this.formPackage.package_search_engine = this.formPackage.item_package_search_engine[0].uuid
            }

            this.formPackage.package_top_ranking = this.formPackage.item_package_top_ranking[2].uuid
            this.getTablePrice()
            
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading_main = false)
      },
      getDataTopRanking(serp_provider_uuid){
        this.loading_main = true
        this.loading_main_price = true
        this.formPackage.item_package_limit = []
        this.axios.post('/package/boost-keyword/get-data-top-ranking',{
          serp_provider_uuid: serp_provider_uuid
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.formPackage.item_package_top_ranking = res.data.serp_top_ranking_provider
            this.formPackage.package_top_ranking = this.formPackage.item_package_top_ranking[2].uuid
            this.getTablePrice()
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading_main = false)
      },
      getTablePrice(){
        this.loading_main_price = true
        this.formPackage.item_package_limit = []
        this.axios.post('/package/boost-keyword/get-data-price',{
          date_uuid : this.formPackage.package_date,
          top_ranking_uuid : this.formPackage.package_top_ranking,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.formPackage.item_package_limit = res.data.package_limit_price
            this.setDataPackage()
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading_main_price = false)
      },
      selectPackageDate(){
        this.getTablePrice()
      },
      selectPackageSearchEngine(){
        this.getDataTopRanking(this.formPackage.package_search_engine)
      },
      selectTopRanking(){
        this.getTablePrice()
      },
      SubmitBuyPackage() {
        if(!this.formPackage.selected_limit_active){
          this.$root.$snackbar(this.$t('select_package'))
          return false
        } 

        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_create_buy_package'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true
            let data_post = {
              limit_active_uuid: this.formPackage.selected_limit_active,
              date_uuid : this.formPackage.package_date,
              top_ranking_uuid : this.formPackage.package_top_ranking,
            }
            if(this.$route.query && this.$route.query.enterprise_uuid){
              data_post['enterprise_uuid'] = this.$route.query.enterprise_uuid
            }
            this.axios.post('/package/boost-keyword/add', data_post)
            .then(res => {
              if (res.data.status === 'success') {
                this.$root.$refs.master.getCredit()
                this.$root.$snackbar(this.$t('buy_package_success'))
                let data_package_boost_keyword = this.$store.state.app.data_package_boost_keyword
                data_package_boost_keyword['formPackage'] = null
                this.$store.dispatch('setDataPackageBoostKeyword', data_package_boost_keyword)
                //this.$root.$refs.dataTableReload()
                this.$router.push({
                  name: 'tracking-package',
                  query: this.$route.query,
                  params: {
                    tab: 'package_boost_keyword'
                  } 
                })
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                if(err.response.data.type == 'credit_error'){
                  if(this.$route.query && this.$route.query.enterprise_uuid){
                    this.$root.$snackbar(err.response.data.msg)
                  }
                  else{
                    this.$root.$snackbar(err.response.data.msg)
                    this.$router.push({
                      name: 'billing-payment', 
                      query: this.$route.query, 
                      params: { 
                        credit: err.response.data.credit
                      }
                    })
                  }
                }
                else{
                  this.$root.$snackbar(err.response.data.msg)
                }
              }
            })
            .finally(() => this.loading = false)
          }
        })
      },
    }
  }
</script>
<style>
.custom-text-field{
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;
}
</style> 