<template>
  <div class="viewContainer" >
    <v-container
      :class="['pa-4', 'pa-sm-8', $store.state.app.classExpandContainer]"
    >
      <v-btn 
        v-if="$route.query && $route.query.enterprise_uuid"
        :to="{ name: 'admin.users' }"
        small
        rounded
        depressed 
        class="no-caps mb-4"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-card class="content-tabs-wrapper pa-4 pa-sm-8" elevation="0">
        <menu_keyword/>
        <!-- <v-container fluid v-if="loading" class="fill-height">
          <v-row 
            align="center"
            justify="center"
            class="fill-height"
          >
            <v-progress-circular
              :size="32"
              width="3"
              color="ctaBg"
              indeterminate
              class="ma-12"
            ></v-progress-circular>
          </v-row>
        </v-container> -->

        <v-tabs 
          v-model="tab_package"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mb-3"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab class="no-caps" :href="'#package'" :ripple="false">
            {{ $t('package_tracking_keyword') }}
          </v-tab>
          <v-tab class="no-caps" :href="'#package_boost_keyword'" :ripple="false">
            {{ $t('package_boost_keyword') }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab_package" :touchless="false">
          <v-tab-item :transition="false" :reverse-transition="false" :value="'package'">
            <panel_package />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false" :value="'package_boost_keyword'">
            <panel_package_boost_keyword />
          </v-tab-item>
        </v-tabs-items>
    </v-card>
  </v-container>
  </div>
</template>
<script>
  
  export default {
    data: () => ({
      tab_package: 'package',
    }),
    created () {
      if(!this.$auth.user().page_package){
        this.$router.push({
          name: 'settings.profile',
        })
      }

      //console.log(this.$route.params)
      if(this.$route.params && this.$route.params.tab){
        this.tab_package = this.$route.params.tab
      }
    },
  }
</script>
