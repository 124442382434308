<template>
  <div class="viewContainer" >
    <v-container
      :class="['pa-4', 'pa-sm-8', $store.state.app.classExpandContainer]"
    >

      <v-card class="content-tabs-wrapper pa-4 pa-sm-8" elevation="0">
        <v-btn 
          :to="{ name: 'admin.tracking-manage-package' }"
          small
          rounded
          depressed 
          class="no-caps mb-4"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          {{ $t('back') }}
        </v-btn>
        <data-table
          model="App\Models\KeywordPackageDate"
          where="keyword_package_1_uuid"
          :where_value="uuid"
        >
          <template v-slot:empty-head>
            <v-icon size="72">mdi-folder-multiple</v-icon>
          </template>
          <template v-slot:empty-text>
          </template>
        </data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: true,
    uuid: null,
    search: '',
  }),
  created () {
    // Set locale
    this.locale = (this.$auth.check()) ? this.$auth.user().locale: Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
    this.moment.locale(this.locale.substr(0,2))
    if(this.$route.query && this.$route.query.uuid){
      this.uuid= this.$route.query.uuid
    }
  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).tz(this.$auth.user().timezone).format(format)
      }
      return dt
    },
  }
}
</script>