<template>
  <div class="viewContainer" >
    <v-container
      :class="['pa-4', 'pa-sm-8', $store.state.app.classExpandContainer]"
    >
      <v-card class="content-tabs-wrapper pa-4 pa-sm-8" elevation="0">

        <v-tabs 
          v-model="tab"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mb-3"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab class="no-caps" :href="'#package'" :ripple="false">
            {{ $t('package_tracking_keyword') }}
          </v-tab>
          <v-tab class="no-caps" :href="'#package_boost_keyword'" :ripple="false">
            {{ $t('package_boost_keyword') }}
          </v-tab>
          <v-tab class="no-caps" :href="'#service'" :ripple="false">
            {{ $t('service') }}
          </v-tab>
          <v-tab class="no-caps" :href="'#extra'" :ripple="false">
            {{ $t('extras') }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" :touchless="false">
          <v-tab-item :transition="false" :reverse-transition="false" :value="'package'">
            <panel_package_keyword_full_data />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false" :value="'package_boost_keyword'">
            <panel_package_boost_keyword_full_data />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false" :value="'service'">
            <panel_service_full_data />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false" :value="'extra'">
            <panel_extra_full_data />
          </v-tab-item>
          
        </v-tabs-items>

      </v-card>

      
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    locale: 'th',
    tab: 'package',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: true,
    business: null,
    search: '',
  }),
  created () {
    // Set locale
    this.locale = (this.$auth.check()) ? this.$auth.user().locale: Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
    this.moment.locale(this.locale.substr(0,2))

  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).tz(this.$auth.user().timezone).format(format)
      }
      return dt
    },
  }
}
</script>